import React from 'react'
import { Dialog, DialogTitle, DialogContent, Avatar } from '@mui/material';
import { BASE_API_URL } from '../../services/config';
import moment from 'moment';
import { CheckCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { formatMoney } from '../../helper';
import Divider from '@mui/material/Divider';

export default function ViewModel({ open, handleClose, data }) {
    return (
        <Dialog
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='border-b relative'>
                <div onClick={handleClose} className='absolute cursor-pointer right-4 p-3' >
                    <XMarkIcon className='w-7 h-7 text-black' />
                </div>
                <div className="flex gap-3 items-center" >
                    <Avatar
                        alt={data?.user?.firstName}
                        src={`${BASE_API_URL}/${data?.user?.profileImage}`}
                        style={{ height: "50px", width: "50px" }}
                    />
                    <div>
                        <div className="font-poppins" >{data?.user?.firstName + " " + data?.user?.lastName}</div>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent  >
                <h6 className="font-bold mb-4 text-[1.2rem] pt-3" >Member Details</h6>
                <div className='grid lg:grid-cols-2 gap-5 pb-5 border-b ' >
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Member ID :</div>
                        <div className="font-poppins" >{data?.user?._id}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Created Date :</div>
                        <div className="font-poppins" >{moment(data?.createdAt).format("DD-MMM-YYYY")}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Mobile No :</div>
                        <div className="font-poppins" >{data?.user?.mobile}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Email :</div>
                        <div className="font-poppins" >{data?.user?.email}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Role :</div>
                        <div className="font-poppins" >{data?.role?.name}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Campaign User ID :</div>
                        <div className="font-poppins" >{data?._id}</div>
                    </div>
                </div>
                <h6 className="font-bold mb-4 text-[1.2rem] pt-3" >Campaign Details</h6>
                <div className='grid lg:grid-cols-2 gap-5 pb-5 border-b ' >
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Campaign :</div>
                        <div className="font-poppins" >{data?.campaign?.name}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Campaign Term ID :</div>
                        <div className="font-poppins" >{data?.campaignTerm?._id}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Start Date :</div>
                        <div className="font-poppins" >{data?.campaignTerm?.startDate ? moment(data?.campaignTerm?.startDate).format("Do MMM YYYY") : ""}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >End Date :</div>
                        <div className="font-poppins" >{data?.campaignTerm?.endDate ? moment(data?.campaignTerm?.endDate).format("Do MMM YYYY") : ""}</div>
                    </div>
                </div>
                <h6 className="font-bold mb-4 text-[1.2rem] pt-3" >Payment Details</h6>
                <div className='grid lg:grid-cols-2 gap-5 pb-5 border-b ' >
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Gateway :</div>
                        <div className="font-poppins" >{data?.campaignUserTermPayment?.gateway}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Amount :</div>
                        <div className="font-poppins" >{formatMoney(data?.campaignUserTermPayment?.amount)}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Currency :</div>
                        <div className="font-poppins" >{data?.campaignUserTermPayment?.currency}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Is Paid :</div>
                        <div className="font-poppins" >{data?.campaignUserTermPayment?.isPaid ? <CheckCircleIcon className='w-5 h-5 text-green-500' /> : <XCircleIcon className='w-5 h-5 text-red-500' />}</div>
                    </div>
                </div>
                <h6 className="mt-4 mb-4 text-[1.2rem] font-bold" >Register Content</h6>
                <table className='w-full border' >
                    <thead className='bg-gray-200' >
                        <tr className="font-poppins font-semibold text-sm" >
                            <td className='py-2 px-2' >#</td>
                            <td className='py-2 px-2' >Question</td>
                            <td className='py-2 px-2' >Answer</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.campaignUserTerm.registerContent?.map((item, index) => {
                            return (
                                <tr className="font-poppins text-sm even:bg-gray-100" key={index} >
                                    <td className='py-2 px-2' >{index + 1}</td>
                                    <td className='py-2 px-2' >{item?.question}</td>
                                    <td className='py-2  px-2' >{item?.answer}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </DialogContent>
        </Dialog>
    )
}
