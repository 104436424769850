import React, { useContext, useEffect, useState } from 'react'
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout'
import Breadcrumbs from '../../components/Breadcrumbs'
import Title from '../../components/Title'
import { MainContext } from '../../context/MainContext'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import PrimaryInput from '../../components/PrimaryInput'
import { formatMoney } from '../../helper'
import PrimaryButton from '../../components/PrimaryButton'
import moment from 'moment'
import { PencilSquareIcon } from '@heroicons/react/20/solid'
import CreateModel from '../../components/CampaignTerm/CreateModel'
import UpdateModel from '../../components/CampaignTerm/UpdateModel'
import LoadingSpinner from '../../components/common/LoadingSpinner'

const breadcrumbData = [
    {
        name: "Campaigns",
        href: null
    },
    {
        name: "Campaign Terms",
        href: null
    }
]

export default function CampaignTerm() {
    document.title = "CCC Admin - Campaign Terms"

    const { fetchCampaigns, campaigns, loadingBackground } = useContext(MainContext)

    const [expandedPanel, setExpandedPanel] = useState("")

    const [selectedTerm, setSelectedTerm] = useState(null)

    const [showModel, setShowModel] = useState(false)
    const [showUpdateModel, setShowUpdateModel] = useState(false)

    useEffect(() => {
        fetchCampaigns()
    }, [])



    return (
        <AuthenticatedLayout>
            <div className='p-5' >
                <Breadcrumbs data={breadcrumbData} />
                <Title>Campaign Terms</Title>
            </div>

            <div className='px-5' >
                <div className='flex justify-end items-end mb-5' >
                    <PrimaryButton onClick={() => {
                        setShowModel(true)
                    }} className="w-[20%]" >Create</PrimaryButton>
                </div>
                {loadingBackground && <LoadingSpinner />}
                {campaigns?.map((row, index) => {
                    return (
                        <Accordion expanded={expandedPanel === `panel${index}`} onChange={() => setExpandedPanel(`panel${index}`)} key={index} >
                            <AccordionSummary
                                expandIcon={<ChevronDownIcon className='text-[#00000047] h-6 w-6' />}
                                aria-controls={`panel${index}a-header`}
                                id={`panel${index}a-header`}
                                sx={{ backgroundColor: "#F9F9F9" }}
                            >
                                <div className='capitalize font-semibold' >{row?.name}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {row?.campaignTerms?.length === 0 &&
                                    <div>No Campaign Terms</div>
                                }
                                {row?.campaignTerms?.map((campaignTerm, campaignTermIndex) => {
                                    return (
                                        <div key={campaignTermIndex} className='border-b-2 flex flex-col gap-2 pb-10 pt-5 relative' >
                                            <div className='flex lg:flex-row flex-col gap-5 justify-between' >
                                                <div className='grid lg:grid-cols-2 gap-x-10 gap-2 text-sm' >
                                                    <div>Start Date :<br className='block lg:hidden' ></br> <b>{moment(campaignTerm?.startDate).format("Do MMM YYYY")}</b></div>
                                                    <div>End Date :<br className='block lg:hidden' ></br> <b>{moment(campaignTerm?.endDate).format("Do MMM YYYY")}</b></div>
                                                    <div>Registration Start Date :<br className='block lg:hidden' ></br> <b>{moment(campaignTerm?.registrationStartDate).format("Do MMM YYYY")}</b></div>
                                                    <div>Registration End Date :<br className='block lg:hidden' ></br> <b>{moment(campaignTerm?.registrationEndDate).format("Do MMM YYYY")}</b></div>
                                                </div>
                                                <div className='flex flex-col lg:items-end gap-2' >
                                                    <button onClick={() => {
                                                        setShowUpdateModel(true)
                                                        setSelectedTerm({ ...campaignTerm, campaignName: row?.name, campaign: row?._id, selectedCampaignTerms: row?.campaignTerms })
                                                    }}
                                                        className='bg-app-blue p-3 rounded-lg w-fit' >
                                                        <PencilSquareIcon className='text-white w-5 h-5' />
                                                    </button>
                                                    {campaignTerm?.isActive && <div className='bg-[#35C003] rounded h-8 px-10 text-white w-fit flex justify-center items-center text-sm' >Active</div>}
                                                </div>
                                            </div>

                                            <div className=' lg:w-[50%] mt-5 lg:mt-0 text-sm' >
                                                <PrimaryInput
                                                    disabled
                                                    label={"Campaign Target Amount"}
                                                    value={formatMoney(campaignTerm?.target)}
                                                />
                                                <div className='my-4' >Campaign Roles Min Targets</div>
                                                <div className='flex flex-col gap-3' >
                                                    {campaignTerm?.roleMinTarget?.map((roleMinTargetRow, roleMinTargetIndex) => {
                                                        const role = row?.campaignRoles?.find(row => row?._id === roleMinTargetRow?.role)
                                                        return (
                                                            <div key={roleMinTargetIndex} className='flex items-center gap-5' >
                                                                <div className='w-[7rem]' >{role?.name}</div>
                                                                <PrimaryInput
                                                                    disabled
                                                                    value={formatMoney(roleMinTargetRow?.minTarget)}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </div>

            <CreateModel
                open={showModel}
                handleClose={() => {
                    setShowModel(false)
                }}
                handleCloseAndFetch={() => {
                    fetchCampaigns()
                    setShowModel(false)
                }}
            />

            <UpdateModel
                open={showUpdateModel}
                dataItem={selectedTerm}
                handleClose={() => {
                    setShowUpdateModel(false)
                    setTimeout(() => {
                        setSelectedTerm(null);
                    }, 560);
                }}

                handleCloseAndFetch={() => {
                    fetchCampaigns()
                    setShowUpdateModel(false)
                    setTimeout(() => {
                        setSelectedTerm(null);
                    }, 560);
                }}
            />

        </AuthenticatedLayout>
    )
}
