import { api } from './api';

export const getCommitteeMembersApi = (params) => {
    return api.post('/users/committee-member-users', params);
};


export const getAllActiveUsersApi = () => {
    return api.get('/users/active-users/get');
};

export const createCommitteeMembersApi = (params) => {
    return api.post('/committee-members/create', params);
};

export const removeCommitteeMembersApi = (committeeMemberId) => {
    return api.delete(`/committee-members/${committeeMemberId}/remove`);
};

