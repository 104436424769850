import React from 'react'

export default function Checkbox({ id, checked, children, onChange, error }) {
    return (
        <div className='flex items-center ' >
            <input checked={checked} onChange={e => onChange()} id={id} type="checkbox" value="" className={` w-4 h-4 accent-app-blue text-red-600 bg-gray-100 rounded focus:ring-app-blue dark:focus:ring-app-blue dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`} />
            <label htmlFor={id} className={`${error? "text-red-600": "text-black"} ml-2 text-sm font-open-sans font-light`}>{children}</label>
        </div>
    )
}
