import { BellIcon } from '@heroicons/react/20/solid'
import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../context/MainContext'
import { BASE_API_URL } from '../services/config'

export default function MainHeader() {
    const { userData, fetchMemberRequests, memberRequests } = useContext(MainContext)

    const [showMenu, setShowMenu] = useState(false)


    useEffect(() => {
        fetchMemberRequests()
    }, [])



    const onLogoutClick = () => {
        localStorage.clear()
        window.location.href = "/"
    }

    return (
        <div className='h-16 w-full flex items-center justify-between px-10 bg-app-blue'>
            <div className='flex items-center gap-5' >
                <button onClick={() => window.location.href = "/member-requests"} className='relative'>
                    <div className={`${memberRequests?.length > 0 ? "opacity-100" : "opacity-0"} transition-all w-3 h-3 rounded-full bg-red-600 absolute -top-1 -right-1`} ></div>
                    <BellIcon className='w-6 h-6 text-white' />
                </button>
                {/* <button className='relative' >
                    <div className='w-3 h-3 rounded-full bg-red-600 absolute -top-1 -right-1' ></div>
                    <EnvelopeIcon className='w-6 h-6 text-white' />
                </button> */}
            </div>
            <div className='relative' >
                <button onClick={() => setShowMenu(!showMenu)} className='flex items-center gap-2 z-[9]' >
                    <img alt={userData?.firstName} src={userData?.profileImage ? BASE_API_URL + "/" + userData?.profileImage : '/images/default-profile-image.jpg'} className='h-10 w-10 rounded-full object-cover border' />
                    <div className='text-white' >{userData?.firstName} {userData?.lastName}</div>
                </button>
                <div onClick={() => setShowMenu(!showMenu)} className={`${showMenu ? "block" : "hidden"} w-screen h-screen z-[10] fixed top-0 left-0 bg-[#00000012]`} ></div>
                <div className={`${showMenu ? "block" : "hidden"} bg-white absolute top-[110%] right-[0%] w-[14rem] shadow-lg p-2 rounded-md z-[11]`} >
                    <div className='flex flex-col justify-center items-center text-black my-5' >
                        <div className={` mt-2`} >{userData?.firstName} {userData?.lastName}</div>
                        <div className={` text-sm text-app-blue capitalize`} >{userData?.role}</div>
                    </div>
                    <button onClick={() => onLogoutClick()} className='flex items-center justify-center gap-2 py-2 px-4 hover:bg-app-light-blue w-full rounded-md' >
                        <img alt='logout' src='/icons/logout.svg' className='h-5 w-5' />
                        <div>Logout</div>
                    </button>
                </div>
            </div>
        </div>
    )
}
