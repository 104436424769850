import { createContext, useEffect, useState } from "react";
import { getMeApi } from "../services/userApis";
import { getCampaignsApi } from "../services/campaignApis";
import { getMemberRequestsApi, getMemberRequestsQuickApi } from "../services/memberRequestApis";


const initialValue = {
    userData: {},
};


const MainContext = createContext(initialValue);


const MainContextProvider = ({ children }) => {
    const [userData, setUserData] = useState(localStorage.user ? JSON.parse(localStorage.user) : {})
    const [campaigns, setCampaigns] = useState([])
    const [loadingBackground, setLoadingBackground] = useState(false)
    const [loadingMemberRequests, setLoadingMemberRequests] = useState(false)

    const [memberRequests, setMemberRequests] = useState([])
    const [memberRequestData, setMemberRequestData] = useState([])


    const token = localStorage.access_token

    useEffect(() => {
        if (token && Object?.keys(userData)?.length === 0) {
            fetchUserData();
        }
    }, [token])

    const fetchUserData = async () => {
        setLoadingBackground(true)
        try {
            const { data } = await getMeApi();
            localStorage.setItem('user', JSON.stringify(data))
            setUserData(data);
            setLoadingBackground(false)
        } catch (error) {
            setLoadingBackground(false)
            console.log("USER FETCH ", error);
        }
    }

    const fetchCampaigns = async () => {
        setLoadingBackground(true)
        try {
            const { data } = await getCampaignsApi()
            setCampaigns(data);
            setLoadingBackground(false)
        } catch (error) {
            setLoadingBackground(false)
            console.log("CAMPAIGN FETCH ", error);
        }
    }


    const fetchMemberRequests = async () => {
        setLoadingBackground(true)
        try {
            const { data } = await getMemberRequestsQuickApi()
            setMemberRequests(data);
            setLoadingBackground(false)
        } catch (error) {
            setLoadingBackground(false)
            console.log("MEMBER REQUESTS FETCH ", error);
        }
    }


    const fetchMemberRequestData = async () => {
        setLoadingMemberRequests(true)
        try {
            const { data } = await getMemberRequestsApi();
            setMemberRequestData(data);
            setLoadingMemberRequests(false)
        } catch (error) {
            setLoadingMemberRequests(false)
        }
    }


    return (
        <MainContext.Provider value={{
            userData,
            setUserData,
            fetchUserData,
            campaigns,
            fetchCampaigns,
            loadingBackground,
            fetchMemberRequests,
            memberRequests,
            fetchMemberRequestData,
            memberRequestData,
            loadingMemberRequests,
            setLoadingMemberRequests
        }}>
            {children}
        </MainContext.Provider>
    );
};

export { MainContext, MainContextProvider };
