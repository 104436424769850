import React, { useEffect, useState } from 'react'
import AuthenticatedLayout from '../layouts/AuthenticatedLayout'
import Breadcrumbs from '../components/Breadcrumbs'
import Title from '../components/Title'
import PrimaryButton from '../components/PrimaryButton'
import TableLayout from '../components/common/TableLayout'
import PrimaryInput from '../components/PrimaryInput'
import { getCommitteeMembersApi, removeCommitteeMembersApi } from '../services/committeeMemberApis'
import moment from 'moment'
import { TrashIcon } from '@heroicons/react/24/solid'
import CreateModel from '../components/BoardMembers/CreateModel'
import { toast } from 'react-toastify'


const breadcrumbData = [
    {
        name: "Board Members",
        href: null
    },
]

export default function BoardMembers() {
    document.title = "CCC Admin - Board Members"


    const [committeeMembers, setCommitteeMembers] = useState([])
    const [pageNumber, setPageNumber] = useState(0)

    const [loading, setLoading] = useState(false)
    const [showCreateModel, setShowCreateModel] = useState(false)


    const [filterForm, setFilterForm] = useState({
        firstName: "",
        lastName: "",
        email: ""
    })

    useEffect(() => {
        fetchMembers()
    }, [])

    const fetchMembers = async () => {
        setLoading(true)

        let dataForm = Object.assign({}, filterForm)

        if (dataForm?.firstName !== "") dataForm.firstName = { $regex: '^' + dataForm.firstName + '$', $options: 'i' }
        if (dataForm?.lastName !== "") dataForm.lastName = { $regex: '^' + dataForm.lastName + '$', $options: 'i' }
        if (dataForm?.email !== "") dataForm.email = { $regex: '^' + dataForm.email + '$', $options: 'i' }

        if (dataForm?.firstName === "") delete dataForm.firstName
        if (dataForm?.lastName === "") delete dataForm.lastName
        if (dataForm?.email === "") delete dataForm.email

        try {
            const { data } = await getCommitteeMembersApi(dataForm);
            setCommitteeMembers(data);
            setLoading(false)
        } catch (error) {
            setLoading(true)
            console.log(error);
        }
    }

    const onDeleteClick = async (row) => {
        setLoading(true)
        try {
            const { data } = await removeCommitteeMembersApi(row?.committeeMember?._id)
            setLoading(false)
            toast.success("Board Member Removed")
            fetchMembers()
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    const perPage = 20
    const pagesVisited = pageNumber * perPage
    const pageCount = Math.ceil(committeeMembers?.length / perPage)
    return (
        <AuthenticatedLayout>
            <div className='p-5' >
                <Breadcrumbs data={breadcrumbData} />
                <Title>Board Members</Title>
            </div>
            <div className='px-5 flex justify-end items-end' >
                <PrimaryButton onClick={() => setShowCreateModel(true)} className="w-[20%]" >Create</PrimaryButton>
            </div>

            <div className='grid lg:grid-cols-4 items-end gap-5 flex-wrap w-full px-5 py-10 bg-[#FCFCFF]' >
                <PrimaryInput
                    label={"First Name"}
                    value={filterForm?.firstName}
                    onChange={text => setFilterForm({ ...filterForm, firstName: text })}
                />
                <PrimaryInput
                    label={"Last Name"}
                    value={filterForm?.lastName}
                    onChange={text => setFilterForm({ ...filterForm, lastName: text })}
                />

                <PrimaryInput
                    label={"Email"}
                    value={filterForm?.email}
                    type="email"
                    onChange={text => setFilterForm({ ...filterForm, email: text })}
                />
                <PrimaryButton loading={loading} onClick={() => fetchMembers()} >Filter</PrimaryButton>
            </div>
            <TableLayout
                pagination={pageCount}
                changePage={page => setPageNumber(page)}
                loading={loading}
                data={committeeMembers}
                emptyMessage={"No Board Members Found"}

            >
                <thead className="text-xs text-white uppercase bg-app-blue" >
                    <tr>
                        <th scope="col" className="py-3 px-6">#</th>
                        <th scope="col" className="py-3 px-6">Member ID</th>
                        <th scope="col" className="py-3 px-6">First Name</th>
                        <th scope="col" className="py-3 px-6">Last Name</th>
                        <th scope="col" className="py-3 px-6">Contact No</th>
                        <th scope="col" className="py-3 px-6">Email</th>
                        <th scope="col" className="py-3 px-6">Created Date</th>
                        <th scope="col" className="py-3 px-6"></th>
                    </tr>
                </thead>
                <tbody>
                    {committeeMembers?.slice(pagesVisited, pagesVisited + perPage)?.map((row, index) => {
                        return (
                            <tr key={index} className='odd:bg-[#F8FAFB] even:bg-white'  >
                                <td className="py-3 px-6" >{index + 1}</td>
                                <td className="py-3 px-6" >{row?.committeeMember?._id}</td>
                                <td className="py-3 px-6" >{row?.firstName}</td>
                                <td className="py-3 px-6" >{row?.lastName}</td>
                                <td className="py-3 px-6" >{row?.mobile}</td>
                                <td className="py-3 px-6" >{row?.email}</td>
                                <td className="py-3 px-6" >{moment(row?.createdAt).format("YYYY-MM-DD")}</td>
                                <td className="py-3 px-2" >
                                    <div className='flex gap-2' >
                                        <button
                                            onClick={() => onDeleteClick(row)}
                                            className='p-2 bg-red-800 rounded-lg' >
                                            <TrashIcon className='text-white w-4 h-4' />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </TableLayout>
            <CreateModel
                open={showCreateModel}
                handleClose={() => setShowCreateModel(false)}
                handleCloseAndFetch={() => {
                    setShowCreateModel(false)
                    fetchMembers()
                }}
            />
        </AuthenticatedLayout>
    )
}
