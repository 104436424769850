import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/20/solid';
import PrimaryButton from '../PrimaryButton';
import PrimaryInput from '../PrimaryInput';
import CampaignPicker from '../common/CampaignPicker';
import { getError } from '../../helper';
import moment from 'moment';
import { toast } from 'react-toastify';
import { createCampaignTermApi } from '../../services/campaignTermApi';

export default function CreateModel({ open, handleClose, handleCloseAndFetch }) {

    const [errorObject, setErrorObject] = useState({})
    const [loading, setLoading] = useState(false)
    const [selectedCampaign, setSelectedCampaign] = useState(null)
    const [campaignRoles, setCampaignRoles] = useState([])
    const [selectedCampaignTerms, setSelectedCampaignTerms] = useState([])

    const [createForm, setCreateForm] = useState({
        campaign: "",
        target: "",
        isActive: false,
        startDate: "",
        endDate: "",
        registrationStartDate: "",
        registrationEndDate: ""
    })

    useEffect(() => {
        if (!open) {
            setCreateForm({
                campaign: "",
                target: "",
                isActive: false,
                startDate: "",
                endDate: "",
                registrationStartDate: "",
                registrationEndDate: ""
            })
            setCampaignRoles([])
            setErrorObject({})
        }
    }, [open])



    const onCreateClick = async () => {
        let errorList = {}
        if (createForm.campaign === "") {
            errorList = { ...errorList, campaign: "The campaign field is required" }
        }
        if (createForm.target === "") {
            errorList = { ...errorList, target: "The target field is required" }
        }
        if (createForm.startDate === "") {
            errorList = { ...errorList, startDate: "The start date field is required" }
        }
        if (createForm.endDate === "") {
            errorList = { ...errorList, endDate: "The end date field is required" }
        }

        if (moment(createForm.startDate).isAfter(createForm.endDate)) {
            errorList = { ...errorList, startDate: "The start date cannot be grater than end date" }
        }

        if (createForm.registrationStartDate === "") {
            errorList = { ...errorList, registrationStartDate: "The registration start date field is required" }
        }

        if (createForm.registrationEndDate === "") {
            errorList = { ...errorList, registrationEndDate: "The registration end date field is required" }
        }

        if (moment(createForm.registrationStartDate).isAfter(createForm.registrationEndDate)) {
            errorList = { ...errorList, startDate: "The registration start date cannot be grater than registration end date" }
        }

        campaignRoles?.map(row => {
            if (row?.minTarget === 0 || row?.minTarget === "") {
                errorList = { ...errorList, [row?.name]: `The ${row?.name} field is required` }
            }
            return row;
        })

        if (Object.keys(errorList)?.length > 0) {
            setErrorObject(errorList)
            return false
        }


        const filterData = selectedCampaignTerms?.filter(row => {
            let startDate = moment(row?.startDate)?.format('YYYY-MM-DD')
            let endDate = moment(row?.endDate)?.format('YYYY-MM-DD')
            if (moment(createForm?.startDate)?.isBetween(startDate, endDate)) {
                return true
            }
            if (moment(createForm?.endDate)?.isBetween(startDate, endDate)) {
                return true
            }
            return false
        })
        if (filterData?.length > 0) {
            return toast.error("This Campaign term is overlapping with another campaign term")
        }

        let dataList = Object.assign({}, createForm)

        const filterActiveData = selectedCampaignTerms?.filter(row => row?.isActive)
        if (filterActiveData?.length === 0) {
            dataList.isActive = true
        }

        setLoading(true);
        try {
            const { data } = await createCampaignTermApi({ ...dataList, roleMinTarget: campaignRoles })
            toast.success(`${selectedCampaign?.name} Campaign Term Created`)
            setLoading(false);
            handleCloseAndFetch();
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    return (
        <Dialog
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='border-b relative w-[100vw] lg:w-[50vw]'>
                <div onClick={handleClose} className='absolute cursor-pointer right-4 h-[60%] flex justify-center items-center ' >
                    <XMarkIcon className='w-7 h-7 text-black' />
                </div>
                <div>{"Created CampaignTerm"}</div>
            </DialogTitle>
            <DialogContent  >
                <div className='grid gap-5 py-5' >
                    <CampaignPicker
                        error={getError('campaign', errorObject)}
                        onChange={campaign => {
                            setSelectedCampaignTerms(campaign?.campaignTerms)
                            setCreateForm({
                                ...createForm,
                                campaign: campaign?._id,
                            })
                            setSelectedCampaign(campaign)
                            setCampaignRoles(campaign?.campaignRoles.map(row => {
                                return {
                                    name: row?.name,
                                    role: row?._id,
                                    minTarget: ""
                                }
                            }))
                        }}
                    />
                    <hr className='border-2' ></hr>

                    <PrimaryInput
                        error={getError('target', errorObject)}
                        label={"Target *"}
                        type="number"
                        value={createForm?.target}
                        onChange={text => { delete errorObject.target; setCreateForm({ ...createForm, target: text }) }}
                    />
                    <div className='grid lg:grid-cols-2 gap-5' >
                        <PrimaryInput
                            error={getError('startDate', errorObject)}
                            label={"Start Date *"}
                            type="date"
                            value={createForm?.startDate}
                            onChange={text => { delete errorObject.startDate; setCreateForm({ ...createForm, startDate: text }) }}
                        />
                        <PrimaryInput
                            error={getError('endDate', errorObject)}
                            label={"End Date *"}
                            type="date"
                            value={createForm?.endDate}
                            onChange={text => { delete errorObject.endDate; setCreateForm({ ...createForm, endDate: text }) }}
                        />
                    </div>
                    <div className='grid lg:grid-cols-2 gap-5' >
                        <PrimaryInput
                            error={getError('registrationStartDate', errorObject)}
                            label={"Registration Start Date *"}
                            type="date"
                            value={createForm?.registrationStartDate}
                            onChange={text => { delete errorObject.registrationStartDate; setCreateForm({ ...createForm, registrationStartDate: text }) }}
                        />
                        <PrimaryInput
                            error={getError('registrationEndDate', errorObject)}
                            label={"Registration End Date *"}
                            type="date"
                            value={createForm?.registrationEndDate}
                            onChange={text => { delete errorObject.registrationEndDate; setCreateForm({ ...createForm, registrationEndDate: text }) }}
                        />
                    </div>
                    {campaignRoles?.length > 0 && <div>Role Minimum Target</div>}
                    <div className='grid lg:grid-cols-2 gap-5' >
                        {campaignRoles?.map((row, index) => {
                            return (
                                <PrimaryInput
                                    key={index}
                                    error={getError(`${row?.name}`, errorObject)}
                                    label={row?.name}
                                    type="number"
                                    value={row?.minTarget}
                                    onChange={text => {
                                        delete errorObject.firstName;
                                        let tempCampaignRoles = [...campaignRoles]
                                        let newItem = { ...row, minTarget: text }
                                        tempCampaignRoles.splice(index, 1, newItem)
                                        setCampaignRoles(tempCampaignRoles)
                                    }}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className='my-5' >
                    <PrimaryButton loading={loading}
                        onClick={() => onCreateClick()}
                    >{"Create"}</PrimaryButton>
                </div>
            </DialogContent>
        </Dialog>
    )
}
