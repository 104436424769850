import React, { useState, useEffect } from 'react'
import AuthenticatedLayout from '../layouts/AuthenticatedLayout'
import Breadcrumbs from '../components/Breadcrumbs'
import Title from '../components/Title'
import CampaignPicker from '../components/common/CampaignPicker'
import PrimaryButton from '../components/PrimaryButton'
import MainPicker from '../components/common/MainPicker'
import moment from 'moment'
import Checkbox from '../components/common/Checkbox'
import { getDonationHistoryApi } from '../services/donationApis'
import TableLayout from '../components/common/TableLayout'
import { getCampaignUserByQueryApi } from '../services/campaignUserApis'

const breadcrumbData = [
    {
        name: "Donation History",
        href: null
    },
]

export default function DonationHistory() {
    document.title = "CCC Admin - Donation History"

    const [campaign, setCampaign] = useState(null)
    const [campaignTerms, setCampaignTerms] = useState([])
    const [campaignTerm, setCampaignTerm] = useState(null)
    const [campaignUsers, setCampaignUsers] = useState([])
    const [campaignUser, setCampaignUser] = useState(null)
    const [year, setYear] = useState("")


    const [byCampaignTerm, setByCampaignTerm] = useState(false)

    const [donations, setDonations] = useState([])
    const [pageNumber, setPageNumber] = useState(0)

    const [loading, setLoading] = useState(false)

    const perPage = 20
    const pagesVisited = pageNumber * perPage
    const pageCount = Math.ceil(donations?.length / perPage)


    useEffect(() => {
        // fetchDonations()
    }, [])



    const fetchDonations = async () => {

        let dataForm = {
            campaign: campaign?._id,
            campaignTerm,
            campaignUser
        }

        if (dataForm?.campaign === "" || dataForm?.campaign === null) delete dataForm.campaign
        if (dataForm?.campaignTerm === "" || dataForm?.campaignTerm === null) delete dataForm.campaignTerm
        if (dataForm?.campaignUser === "" || dataForm?.campaignUser === null) delete dataForm.campaignUser
        if (dataForm?.year === "") delete dataForm.year

        if (year !== "") {
            let createdAt = {
                $gt: moment(year).startOf('year'),
                $lt: moment(year).endOf('year')
            }
            dataForm = { ...dataForm, createdAt }
        }

        setLoading(true)
        try {
            const { data } = await getDonationHistoryApi(dataForm)
            setDonations(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    const fetchCampaignUsers = async (campaignId) => {
        try {
            const { data } = await getCampaignUserByQueryApi({ campaign: campaignId })
            setCampaignUsers(data);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <AuthenticatedLayout>
            <div className='p-5' >
                <Breadcrumbs data={breadcrumbData} />
                <Title>Donation History</Title>
            </div>

            <div className='grid lg:grid-cols-3 items-end gap-5 flex-wrap w-full px-5 py-10 bg-[#FCFCFF]' >
                <CampaignPicker
                    defaultItemTitle={"All"}
                    onChange={campaign => {
                        if(campaign === null){
                            setCampaignUser(null)
                            setCampaignTerm(null)
                        }
                        setCampaign(campaign)
                        setCampaignTerms(campaign?.campaignTerms)
                        setCampaignUsers([])
                        fetchCampaignUsers(campaign?._id)
                    }}
                />
                <MainPicker
                    label={"Campaign User"}
                    defaultItemTitle={"All"}
                    onChange={value => {
                        setYear("")
                        if (value === "") {
                            return setCampaignUser(null)
                        }
                        setCampaignUser(value)
                    }}
                    value={campaignUser}
                >
                    {campaignUsers?.map((row, index) => {
                        return (
                            <option key={index} value={row?._id} >{row?.user?.firstName} {row?.user?.lastName}</option>
                        )
                    })}
                </MainPicker>
                {byCampaignTerm &&
                    <MainPicker
                        label={"Campaign Term"}
                        defaultItemTitle={"All"}
                        onChange={value => {
                            setYear("")
                            if (value === "") {
                                return setCampaignTerm(null)
                            }
                            setCampaignTerm(value)
                        }}
                        value={campaignTerm}
                    >
                        {campaignTerms?.map((row, index) => {
                            return (
                                <option key={index} value={row?._id} >{moment(row?.startDate).format("YYYY-MM-DD")} - {moment(row?.endDate).format("YYYY-MM-DD")}</option>
                            )
                        })}
                    </MainPicker>
                }

                {!byCampaignTerm &&
                    <MainPicker
                        label={"Year"}
                        defaultItemTitle={"All"}
                        onChange={value => { setCampaignTerm(null); setYear(value) }}
                        value={campaignTerm}
                    >
                        <option value={"2021"} >2021</option>
                        <option value={"2022"} >2022</option>
                        <option value={"2023"} >2023</option>
                    </MainPicker>
                }
                <PrimaryButton loading={loading}
                    onClick={() => fetchDonations()}
                >Filter</PrimaryButton>
                <Checkbox id={"byCampaignTerm"} checked={byCampaignTerm} onChange={() => setByCampaignTerm(!byCampaignTerm)} >By Campaign Terms</Checkbox>
            </div>

            <TableLayout
                pagination={pageCount}
                changePage={page => setPageNumber(page)}
                loading={loading}
                data={donations}
                emptyMessage={"No Donations Found"}

            >
                <thead className="text-xs text-white uppercase bg-app-blue" >
                    <tr>
                        <th scope="col" className="py-3 px-6">Donation ID</th>
                        <th scope="col" className="py-3 px-6">Date</th>
                        <th scope="col" className="py-3 px-6">Donated To</th>
                        <th scope="col" className="py-3 px-6">Campaign</th>
                        <th scope="col" className="py-3 px-6">Currency</th>
                        <th scope="col" className="py-3 px-6">Amount</th>
                        <th scope="col" className="py-3 px-6">Amount (LKR)</th>
                        <th scope="col" className="py-3 px-6">Campaign Term</th>
                        <th scope="col" className="py-3 px-6"></th>
                    </tr>
                </thead>
                <tbody>
                    {donations?.slice(pagesVisited, pagesVisited + perPage)?.map((row, index) => {
                        return (
                            <tr key={index} className='odd:bg-[#F8FAFB] even:bg-white'  >
                                <td className="py-3 px-6" >{row?.paymentReference}</td>
                                <td className="py-3 px-6 whitespace-nowrap" >{moment(row?.createdAt).format("YYYY-MM-DD")}</td>
                                <td className="py-3 px-6" >{row?.campaignUser?.user?.firstName} {row?.campaignUser?.user?.lastName}</td>
                                <td className="py-3 px-6 whitespace-nowrap" >{row?.campaign?.name}</td>
                                <td className="py-3 px-6" >{row?.currency}</td>
                                <td className="py-3 px-6" >{row?.amount}</td>
                                <td className="py-3 px-6" >{row?.amountLocal}</td>
                                <td className="py-3 px-6" >{row?.campaignTerm?.startDate && moment(row?.campaignTerm?.startDate).format("YYYY-MM-DD")} - {row?.campaignTerm?.endDate && moment(row?.campaignTerm?.endDate).format("YYYY-MM-DD")}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </TableLayout>

        </AuthenticatedLayout>
    )
}
