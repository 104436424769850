import React from 'react'
import { Dialog, DialogTitle, DialogContent, Avatar } from '@mui/material';
import { BASE_API_URL } from '../../services/config';
import { XMarkIcon } from '@heroicons/react/20/solid';

export default function BoardMemberApprovalSummaryModel({ open, handleClose, data }) {
    return (
        <Dialog
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='border-b relative w-[100vw] lg:w-[50vw]'>
                <div onClick={handleClose} className='absolute cursor-pointer right-4 p-3 top-2' >
                    <XMarkIcon className='w-7 h-7 text-black' />
                </div>
                <div>Board Member Approval Summary</div>
            </DialogTitle>
            <DialogContent  >
                <table className='w-full border mt-10' >
                    <thead className='bg-gray-200' >
                        <tr className="font-poppins font-semibold text-sm" >
                            <td className='py-2 px-2' >#</td>
                            <td className='py-2 px-2' >Board Member</td>
                            <td className='py-2 px-2' >Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, index) => {
                            return (
                                <tr className="font-poppins text-sm even:bg-gray-100" key={index} >
                                    <td className='py-2 px-2' >{index + 1}</td>
                                    <td className='py-2 px-2' >
                                        <div className="flex gap-3 items-center" >
                                            <Avatar
                                                alt={item?.committeeMember?.user?.firstName}
                                                src={`${BASE_API_URL}/${item?.committeeMember?.user?.profileImage}`}
                                                style={{ height: "30px", width: "30px" }}
                                            />
                                            <div>
                                                <div className="font-poppins" >{item?.committeeMember?.user?.firstName + " " + item?.committeeMember?.user?.lastName}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={`${item?.status === "pending" ? "text-orange-400" : ""} ${item?.status === "approved" ? "text-green-400" : ""} ${item?.status === "rejected" ? "text-red-400" : ""} font-semibold py-2  px-2 capitalize`} >{item?.status}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </DialogContent>
        </Dialog>
    )
}
