import React, { useContext } from 'react'
import { MainContext } from '../../context/MainContext'
import { BASE_API_URL } from '../../services/config'

export default function AccountDetails({ hideTitle }) {
    const { userData } = useContext(MainContext)

    return (
        <div className={`${hideTitle ? "min-h-[7rem]" : "min-h-[12rem]"} flex flex-col justify-center items-center text-black `} >
            <div>
                <img alt={userData?.firstName} src={userData?.profileImage ? BASE_API_URL + "/" + userData?.profileImage : '/images/default-profile-image.jpg'} className={`${hideTitle? "h-10 w-10":"h-20 w-20"} rounded-full  border object-cover`} />
            </div>
            <div className={`${hideTitle ? "hidden" : "block"} mt-2`} >{userData?.firstName} {userData?.lastName}</div>
            <div className={`${hideTitle ? "hidden" : "block"} text-sm text-app-blue capitalize`} >{userData?.role}</div>
        </div>
    )
}
