import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/20/solid';
import PrimaryButton from '../PrimaryButton';
import PrimaryInput from '../PrimaryInput';
import moment from 'moment';
import { getError } from '../../helper';
import { toast } from 'react-toastify';
import { updateCampaignTermApi } from '../../services/campaignTermApi';
import Checkbox from '../common/Checkbox';

export default function UpdateModel({ open, handleClose, dataItem, handleCloseAndFetch }) {

    const [errorObject, setErrorObject] = useState({})
    const [loading, setLoading] = useState(false)

    const [updateForm, setUpdateForm] = useState({})
    const [campaignRoles, setCampaignRoles] = useState([])
    const [selectedCampaignTerms, setSelectedCampaignTerms] = useState([])
    const [endCampaign, setEndCampaign] = useState(false)
    const [activateCampaign, setActivateCampaign] = useState(false)
    const [notifyUsers, setNotifyUsers] = useState(false)

    const filterActiveData = selectedCampaignTerms?.filter(row => row?.isActive)

    useEffect(() => {
      if(!open){
        setEndCampaign(false)
        setActivateCampaign(false)
        setNotifyUsers(false)
      }
    }, [open])
    


    useEffect(() => {
        let updateData = {
            campaign: dataItem?.campaign,
            target: dataItem?.target,
            startDate: moment(dataItem?.startDate)?.format("YYYY-MM-DD"),
            endDate: moment(dataItem?.endDate)?.format("YYYY-MM-DD"),
            registrationStartDate: moment(dataItem?.registrationStartDate)?.format("YYYY-MM-DD"),
            registrationEndDate: moment(dataItem?.registrationEndDate)?.format("YYYY-MM-DD"),
        }
        setUpdateForm(updateData)
        setCampaignRoles(dataItem?.roleMinTarget)
        setSelectedCampaignTerms(dataItem?.selectedCampaignTerms)
    }, [dataItem])


    const onUpdateClick = async () => {
        let errorList = {}
        if (updateForm.campaign === "") {
            errorList = { ...errorList, campaign: "The campaign field is required" }
        }
        if (updateForm.target === "") {
            errorList = { ...errorList, target: "The target field is required" }
        }
        if (updateForm.startDate === "") {
            errorList = { ...errorList, startDate: "The start date field is required" }
        }
        if (updateForm.endDate === "") {
            errorList = { ...errorList, endDate: "The end date field is required" }
        }

        if (moment(updateForm.startDate).isAfter(updateForm.endDate)) {
            errorList = { ...errorList, startDate: "The start date cannot be grater than end date" }
        }

        if (updateForm.registrationStartDate === "") {
            errorList = { ...errorList, registrationStartDate: "The registration start date field is required" }
        }

        if (updateForm.registrationEndDate === "") {
            errorList = { ...errorList, registrationEndDate: "The registration end date field is required" }
        }

        if (moment(updateForm.registrationStartDate).isAfter(updateForm.registrationEndDate)) {
            errorList = { ...errorList, startDate: "The registration start date cannot be grater than registration end date" }
        }

        campaignRoles?.map(row => {
            if (row?.minTarget === 0 || row?.minTarget === "") {
                errorList = { ...errorList, [row?.name]: `The ${row?.name} field is required` }
            }
            return row
        })

        if (Object.keys(errorList)?.length > 0) {
            setErrorObject(errorList)
            return false
        }


        const filterData = selectedCampaignTerms?.filter(row => {
            let startDate = moment(row?.startDate)?.format('YYYY-MM-DD')
            let endDate = moment(row?.endDate)?.format('YYYY-MM-DD')
            if (moment(updateForm?.startDate)?.isBetween(startDate, endDate) && dataItem?._id !== row?._id) {
                return true
            }
            if (moment(updateForm?.endDate)?.isBetween(startDate, endDate) && dataItem?._id !== row?._id) {
                return true
            }
            return false
        })
        if (filterData?.length > 0) {
            return toast.error("This Campaign term is overlapping with another campaign term")
        }

        let dataList = Object.assign({}, updateForm)

        const filterActiveData = selectedCampaignTerms?.filter(row => row?.isActive)
        if (filterActiveData?.length === 0) {
            dataList.isActive = true
        }

        if (endCampaign) {
            dataList.isActive = false
        }

        if(activateCampaign){
            dataList.isActive = true
        }

        if(notifyUsers){
            dataList.notifyUsers = true
        }

        setLoading(true);
        try {
            const { data } = await updateCampaignTermApi(dataItem?._id, { ...dataList, roleMinTarget: campaignRoles })
            toast.success(`${dataItem?.campaignName} Campaign Term Updated`)
            setLoading(false);
            handleCloseAndFetch();
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }


    return (
        <Dialog
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='border-b relative w-[100vw] lg:w-[50vw]'>
                <div onClick={handleClose} className='absolute cursor-pointer right-4 h-[60%] flex justify-center items-center ' >
                    <XMarkIcon className='w-7 h-7 text-black' />
                </div>
                <div className='capitalize' >{`Update ${dataItem?.campaignName} Term`}</div>
            </DialogTitle>
            <DialogContent  >
                <div className='grid gap-5 py-5' >
                    <PrimaryInput
                        error={getError('target', errorObject)}
                        label={"Target *"}
                        type="number"
                        value={updateForm?.target}
                        onChange={text => { delete errorObject.target; setUpdateForm({ ...updateForm, target: text }) }}
                    />
                    <div className='grid lg:grid-cols-2 gap-5' >
                        <PrimaryInput
                            error={getError('startDate', errorObject)}
                            label={"Start Date *"}
                            type="date"
                            value={updateForm?.startDate}
                            onChange={text => { delete errorObject.startDate; setUpdateForm({ ...updateForm, startDate: text }) }}
                        />
                        <PrimaryInput
                            error={getError('endDate', errorObject)}
                            label={"End Date *"}
                            type="date"
                            value={updateForm?.endDate}
                            onChange={text => { delete errorObject.endDate; setUpdateForm({ ...updateForm, endDate: text }) }}
                        />
                    </div>
                    <div className='grid lg:grid-cols-2 gap-5' >
                        <PrimaryInput
                            error={getError('registrationStartDate', errorObject)}
                            label={"Registration Start Date *"}
                            type="date"
                            value={updateForm?.registrationStartDate}
                            onChange={text => { delete errorObject.registrationStartDate; setUpdateForm({ ...updateForm, registrationStartDate: text }) }}
                        />
                        <PrimaryInput
                            error={getError('registrationEndDate', errorObject)}
                            label={"Registration End Date *"}
                            type="date"
                            value={updateForm?.registrationEndDate}
                            onChange={text => { delete errorObject.registrationEndDate; setUpdateForm({ ...updateForm, registrationEndDate: text }) }}
                        />
                    </div>
                    {campaignRoles?.length > 0 && <div>Role Minimum Target</div>}
                    <div className='grid lg:grid-cols-2 gap-5' >
                        {campaignRoles?.map((row, index) => {
                            return (
                                <PrimaryInput
                                    key={index}
                                    error={getError(`${row?.name}`, errorObject)}
                                    label={row?.name}
                                    type="number"
                                    value={row?.minTarget}
                                    onChange={text => {
                                        delete errorObject.firstName;
                                        let tempCampaignRoles = [...campaignRoles]
                                        let newItem = { ...row, minTarget: text }
                                        tempCampaignRoles.splice(index, 1, newItem)
                                        setCampaignRoles(tempCampaignRoles)
                                    }}
                                />
                            )
                        })}
                    </div>
                    {dataItem?.isActive && <Checkbox id={"end-campaign"} checked={endCampaign} onChange={() => setEndCampaign(!endCampaign)} >End Campaign</Checkbox>}
                    {filterActiveData?.length === 0 &&
                        <div className='flex flex-col gap-3' >
                            <Checkbox id={"activate-campaign"} checked={activateCampaign} onChange={() => {
                                setActivateCampaign(!activateCampaign)
                                if (!activateCampaign === false) {
                                    setNotifyUsers(false)
                                }
                            }} >Activate Campaign</Checkbox>
                            <Checkbox id={"notify-campaign"} checked={notifyUsers} onChange={() => {
                                if (activateCampaign === false) {
                                    setActivateCampaign(true)
                                }
                                setNotifyUsers(!notifyUsers)
                            }} >Send Invitation to Users</Checkbox>
                        </div>
                    }
                </div>
                <div className='my-5' >
                    <PrimaryButton loading={loading}
                        onClick={() => onUpdateClick()}
                    >Update</PrimaryButton>
                </div>
            </DialogContent>
        </Dialog>
    )
}
