import { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom'
import MainSideMenu from '../components/MainSideMenu/MainSideMenu';
import MainHeader from '../components/MainHeader';


export default function AuthenticatedLayout({ children }) {
    const navigate = useNavigate()


    useEffect(() => {
        const token = localStorage.getItem('access_token')
        if (!token) {
            localStorage.clear()
            return navigate('/')
        }
    }, [])


    return (
        <div className='flex w-screen min-h-screen relative' >
            <MainSideMenu />
            <div className='w-full' >
                <MainHeader />
                <div className='min-h-[100vh] p-1' >{children}</div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
