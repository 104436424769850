import React, { useState, useEffect, useContext } from 'react'
import AccountDetails from './AccountDetails'
import NavigationLink from './NavigationLink'
import { Bars3Icon } from '@heroicons/react/20/solid'
import { MainContext } from '../../context/MainContext'

const campaignSubList = [
    {
        title: "Members",
        href: "/campaigns/members"
    },
    {
        title: "Campaign Terms",
        href: "/campaigns/campaign-terms"
    },
    {
        title: "Campaign Payments",
        href: "/campaigns/campaign-term-payments"
    }
]

const expandedMenuWidth = "20rem";
const collapsedMenuWidth = "5rem";

export default function MainSideMenu() {
    const { memberRequests } = useContext(MainContext)

    const pathname = window.location.pathname;

    const [campaignsExpandHeight, setCampaignsExpandHeight] = useState('0rem')
    const [menuWidth, setMenuWidth] = useState(expandedMenuWidth)


    useEffect(() => {
        if (pathname.includes('/campaigns/') && campaignsExpandHeight === "0rem") {
            let height = 3.5 * campaignSubList?.length
            return setCampaignsExpandHeight(height + "rem")
        }
    }, [])


    return (
        <div style={{ width: menuWidth }} className='shadow-xl transition-all min-h-screen' >
            <div className='h-16 w-full bg-app-blue flex items-center justify-end px-6'>
                <button id="menuButton" onClick={() => {
                    if (menuWidth === expandedMenuWidth) {
                        setCampaignsExpandHeight("0rem")
                        return setMenuWidth(collapsedMenuWidth)
                    }
                    return setMenuWidth(expandedMenuWidth)
                }} className='' >
                    <Bars3Icon className='w-6 h-6 text-white' />
                </button>
            </div>
            <AccountDetails hideTitle={menuWidth !== expandedMenuWidth} />
            <div className='flex flex-col' >
                <div className={`${menuWidth === expandedMenuWidth ? "block" : "hidden"} mb-2 uppercase text-[#7C7C7C] px-4`} >Navigation</div>
                <NavigationLink hideTitle={menuWidth !== expandedMenuWidth} icon="/icons/navigation/dashboard.svg" title="Dashboard" href="/dashboard" />
                <NavigationLink
                    hideTitle={menuWidth !== expandedMenuWidth}
                    icon="/icons/navigation/campaigns.svg"
                    title="Campaigns"
                    href="/dashboard"
                    expandHeight={campaignsExpandHeight}
                    subList={campaignSubList}
                    onExpandClick={() => {
                        if (campaignsExpandHeight === "0rem") {
                            setMenuWidth(expandedMenuWidth)
                            let height = 3.5 * campaignSubList?.length
                            return setCampaignsExpandHeight(height + "rem")
                        }
                        return setCampaignsExpandHeight("0rem")
                    }}
                />
            </div>
            <hr className='mt-3 mb-7' ></hr>
            <div className='flex flex-col' >
                <div className={`${menuWidth === expandedMenuWidth ? "block" : "hidden"} mb-2 uppercase text-[#7C7C7C] px-4`} >Navigation</div>
                <NavigationLink hideTitle={menuWidth !== expandedMenuWidth} icon="/icons/navigation/donation-history.svg" title="Donation History" href="/donation-history" />
                <NavigationLink hideTitle={menuWidth !== expandedMenuWidth} icon="/icons/navigation/member-requests.svg" title="Member Requests" href="/member-requests" notification={memberRequests?.length} />
                <NavigationLink hideTitle={menuWidth !== expandedMenuWidth} icon="/icons/navigation/board-members.svg" title="Board Members" href="/board-members" />
                <NavigationLink hideTitle={menuWidth !== expandedMenuWidth} icon="/icons/navigation/report.svg" title="Reports" href="/reports" />
            </div>
        </div>
    )
}
