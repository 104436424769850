import axios from 'axios';
import { BASE_API_URL } from './config';
import { toast } from 'react-toastify';


export const api = axios.create({
    baseURL: BASE_API_URL,
});

api.interceptors.request.use(
    async function (config) {
        const token = localStorage.access_token
        config.headers.Authorization = "bearer "+ token;

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.status === 401) {
            localStorage.clear()
            window.location.href = "/"
        }
        if (error?.response?.status === 400) {
            let errorList = error?.response?.data?.message
            if (typeof errorList == "string") {
                toast.error(errorList)
            }
            if (typeof errorList != "string") {
                errorList.forEach(element => {
                    toast.error(element)
                });
            }
        }
        return Promise.reject(error.response.data.message);
    }
);

export default api;
