import React from 'react'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function ConfirmationModel({ open, handleClose, handleCloseAndAction, title, children }) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className='capitalize' >{children}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} className='py-1 px-10 border-red-700 text-red-700 border rounded-md' >No</button>
                    <button onClick={handleCloseAndAction} className='py-1 px-10 border-green-700 text-white bg-green-700 border rounded-md' >Yes</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
