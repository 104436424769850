import React from 'react'

export default function PrimaryInput(props) {
    return (
        <div className='w-full' >
            <label className="block mb-2 text-sm font-medium text-gray-900 ">{props?.label}</label>
            <input
                {...props}
                onWheel={(e) => props?.type === "number" ? e.target.blur() : null}
                onChange={e => props.onChange(e.target.value)}
                className={`${props.error ? "border-red-600" : "border-gray-300"} bg-gray-50 border disabled:bg-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 px-3 `} />
            {props?.error && <small className='text-red-600' >{props?.error}</small>}
        </div>
    )
}
