import { api } from './api';

export const getCampaignsApi = () => {
	return api.get('/campaigns/all-campaign-terms');
};


export const getCampaignsDashboardApi = () => {
	return api.get('/admin/get-campaigns');
};
