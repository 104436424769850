import { useEffect, useState } from 'react'
import GuestLayout from '../layouts/GuestLayout'
import PrimaryButton from '../components/PrimaryButton'
import PrimaryInput from '../components/PrimaryInput'
import { getError, validateEmail } from '../helper'
import { loginApi } from '../services/userApis'

import { useNavigate } from 'react-router-dom'

export default function Login() {
    document.title = "CCC Admin - Login"

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [errorObject, setErrorObject] = useState({})

    const [loginForm, setLoginForm] = useState({
        email: "",
        password: "",
    })

    useEffect(() => {
        if (localStorage?.access_token) {
            return window.location.href = "/dashboard"
        }
    }, [])


    const validateForm = () => {
        let errorList = {}
        if (loginForm.email === "") {
            errorList = { ...errorList, email: "The email field is required" }
        }
        if (loginForm.email !== "" && !validateEmail(loginForm.email)) {
            errorList = { ...errorList, email: "The email field is invalid" }
        }
        if (loginForm.password === "") {
            errorList = { ...errorList, password: "The password field is required" }
        }
        if (Object.keys(errorList)?.length > 0) {
            setErrorObject(errorList)
            return false
        }
        return true
    }

    const onLoginClick = async () => {
        if (!validateForm()) return
        setLoading(true)
        try {
            const { data } = await loginApi(loginForm)
            localStorage.setItem('access_token', data?.access_token)
            localStorage.setItem('user', JSON.stringify(data?.user))
            navigate('dashboard')
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <GuestLayout>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 text-black">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                        Sign in to your account
                    </h1>
                    <PrimaryInput
                        error={getError('email', errorObject)}
                        type="email"
                        label={"Your email"}
                        placeholder="test@test.com"
                        value={loginForm?.email}
                        onChange={text => { delete errorObject.email; setLoginForm({ ...loginForm, email: text }) }}
                    />
                    <PrimaryInput
                        error={getError('password', errorObject)}
                        type="password"
                        label={"Password"}
                        placeholder="Password"
                        value={loginForm?.password}
                        onChange={text => { delete errorObject.password; setLoginForm({ ...loginForm, password: text }) }}
                    />
                    <div className="flex items-center justify-end">
                        <a href="#" className="text-sm font-medium text-app-blue hover:underline">Forgot password?</a>
                    </div>
                    <PrimaryButton className="w-full" loading={loading} onClick={() => onLoginClick()} >Login</PrimaryButton>
                </div>
            </div>
        </GuestLayout>
    )
}
