import React, { useState, useContext } from 'react'
import TableLayout from '../common/TableLayout'
import { changeStatusMemberApi } from '../../services/memberRequestApis'
import { MainContext } from '../../context/MainContext'
import { getApprovalStatus, isApprovalCheckPass } from '../../helper'
import ViewSingleModel from './ViewSingleModel'
import { EyeIcon } from '@heroicons/react/20/solid'
import BoardMemberApprovalSummaryModel from './BoardMemberApprovalSummaryModel'
import { toast } from 'react-toastify'
import ConfirmationModel from '../common/ConfirmationModel'

export default function Tab({ selected, selectedTab }) {
    const { memberRequestData, loadingMemberRequests, setLoadingMemberRequests, fetchMemberRequestData,fetchMemberRequests } = useContext(MainContext)
    const [pageNumber, setPageNumber] = useState(0)

    const [showModel, setShowModel] = useState(false)
    const [showBoardMemberSummaryModel, setShowBoardMemberSummaryModel] = useState(false)
    const [selectedMember, setSelectedMember] = useState(null)
    const [selectedAllRequests, setSelectedAllRequests] = useState(null)


    const [showConfirmationModel, setShowConfirmationModel] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState("")

    const perPage = 10
    const pagesVisited = pageNumber * perPage
    const pageCount = Math.ceil(memberRequestData?.length / perPage)


    const onClickAcceptOrReject = async (row, status) => {
        setLoadingMemberRequests(true)
        try {
            const { data } = await changeStatusMemberApi(row?.campaignUser?._id, { status })
            toast.success(`User Request ${status}`)
            fetchMemberRequestData();
            fetchMemberRequests();
            setLoadingMemberRequests(false)
        } catch (error) {
            setLoadingMemberRequests(false)
        }
    }


    return (
        <div className={`${selected ? "flex" : "hidden"} `} >
            <TableLayout
                pagination={pageCount}
                changePage={page => setPageNumber(page)}
                loading={loadingMemberRequests}
                data={memberRequestData}
                emptyMessage={"No Members Requests"}
            >
                <thead className="text-xs text-white uppercase bg-app-blue" >
                    <tr>
                        <th scope="col" className="py-3 px-6">#</th>
                        <th scope="col" className="py-3 px-6">Member ID</th>
                        <th scope="col" className="py-3 px-6">First Name</th>
                        <th scope="col" className="py-3 px-6">Last Name</th>
                        <th scope="col" className="py-3 px-6">Campaign Name</th>
                        <th scope="col" className="py-3 px-6">Role</th>
                        <th scope="col" className="py-3 px-6">Contact No</th>
                        <th scope="col" className="py-3 px-6"></th>
                    </tr>
                </thead>
                <tbody>
                    {memberRequestData?.slice(pagesVisited, pagesVisited + perPage)?.map((row, index) => {
                        return (
                            <tr key={index} className={`${selectedMember?._id === row?._id ? "bg-blue-100" : "odd:bg-[#F8FAFB] even:bg-white"}  `}  >
                                <td className="py-3 px-6" >{index + 1}</td>
                                <td className="py-3 px-6" >{row?._id}</td>
                                <td className="py-3 px-6" >{row?.firstName}</td>
                                <td className="py-3 px-6" >{row?.lastName}</td>
                                <td className="py-3 px-6" >{row?.campaignUser?.campaign?.name}</td>
                                <td className="py-3 px-6" >{row?.campaignUser?.role?.name}</td>
                                <td className="py-3 px-6" >{row?.mobile}</td>
                                <td className="py-3 px-2" >
                                    <div className='flex gap-2' >
                                        <button
                                            onClick={() => {
                                                setSelectedMember(row)
                                                setShowModel(true)
                                            }}
                                            className='p-2 px-3 bg-black rounded-lg text-white' >
                                            <EyeIcon className='text-white w-5 h-5' />
                                        </button>
                                        {selectedTab === 0 &&
                                            <>
                                                <button
                                                    disabled={!isApprovalCheckPass(row?.approvedRequests?.length, row?.allRequests?.length)}
                                                    onClick={() => {
                                                        setSelectedRowData(row)
                                                        setSelectedStatus("approved")
                                                        setShowConfirmationModel(true)
                                                    }}
                                                    className='disabled:bg-gray-300 p-2 px-3 bg-green-700 rounded-lg text-white' >
                                                    Approve
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setSelectedRowData(row)
                                                        setSelectedStatus("rejected")
                                                        setShowConfirmationModel(true)
                                                    }}
                                                    className='p-2 px-3 bg-red-800 rounded-lg text-white' >
                                                    Reject
                                                </button>
                                            </>
                                        }
                                        {selectedTab === 1 &&
                                            <button
                                                onClick={() => {
                                                    setSelectedAllRequests(row?.allRequests)
                                                    setShowBoardMemberSummaryModel(true)
                                                }}
                                                className={`
                                                    ${getApprovalStatus(row?.approvedRequests?.length, row?.allRequests?.length, row?.rejectedRequests?.length) === "pending" ? "bg-orange-400" : ""} 
                                                    ${getApprovalStatus(row?.approvedRequests?.length, row?.allRequests?.length, row?.rejectedRequests?.length) === "approved" ? "bg-green-400" : ""} 
                                                    ${getApprovalStatus(row?.approvedRequests?.length, row?.allRequests?.length, row?.rejectedRequests?.length) === "rejected" ? "bg-red-400" : ""} 
                                                    p-2 px-3 w-28 capitalize rounded-lg text-white`} >
                                                {getApprovalStatus(row?.approvedRequests?.length, row?.allRequests?.length, row?.rejectedRequests?.length)}
                                            </button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </TableLayout>

            <ViewSingleModel
                data={selectedMember}
                open={showModel}
                handleClose={() => {
                    setShowModel(false)
                    setTimeout(() => {
                        setSelectedMember(null)
                    }, 560);
                }} />

            <BoardMemberApprovalSummaryModel
                data={selectedAllRequests}
                open={showBoardMemberSummaryModel}
                handleClose={() => {
                    setShowBoardMemberSummaryModel(false)
                    setTimeout(() => {
                        setSelectedAllRequests(null)
                    }, 560);
                }} />


            <ConfirmationModel
                title={"Are you sure ?"}
                open={showConfirmationModel}
                handleClose={() => setShowConfirmationModel(false)}
                handleCloseAndAction={() => {
                    onClickAcceptOrReject(selectedRowData, selectedStatus)
                    setShowConfirmationModel(false)
                }}
            >
                You are about to <b>{selectedStatus === "approved" ? "approve" : "reject"}</b> <b>{selectedRowData?.firstName} {selectedRowData?.lastName}</b> for the campaign <b>{selectedRowData?.campaignUser?.campaign?.name}</b> as a <b>{selectedRowData?.campaignUser?.role?.name}</b>.
            </ConfirmationModel>

        </div>
    )
}
