import React from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function NavigationLink({ icon, title, href, notification, subList, onExpandClick, expandHeight, hideTitle }) {

    const pathname = window.location.pathname
    if (subList) {
        return (
            <>
                <button onClick={() => onExpandClick()} className='flex relative items-center justify-between px-5 py-4 text-sm hover:bg-app-light-blue' >
                    <div className='flex gap-2 items-center' >
                        <img alt={title} src={icon} className='h-6 w-6' />
                        <div className={`${hideTitle ? "hidden" : "block"}`} >{title}</div>
                    </div>
                    {notification ?
                        <div className={` ${hideTitle ? "absolute right-1 top-1" : ""} h-5 w-5 rounded-full bg-red-600 text-white text-[0.6rem]  flex justify-center items-center`} >{notification}</div>
                        : null}
                    <ChevronDownIcon className={`${expandHeight === '0rem' ? "" : "rotate-180"} ${hideTitle ? "absolute right-1 top-[20%]" : ""} transition-all h-7 w-7`} />
                </button>
                <div style={{ height: expandHeight }} className='transition-all overflow-hidden' >
                    {subList?.map((row, index) => {
                        return (
                            <a href={row?.href} className={`${row?.href.includes(pathname) ? "bg-app-light-blue" : ""} text-sm  flex items-center justify-between pl-16 pr-3 h-14 hover:bg-app-light-blue`} key={index} >{row?.title}</a>
                        )
                    })}
                </div>
            </>
        )
    }
    return (
        <a href={href} className={`${href.includes(pathname) ? "bg-app-light-blue" : ""} relative flex items-center text-sm justify-between px-5 py-4 hover:bg-app-light-blue`} >
            <div className='flex gap-2' >
                <img alt={title} src={icon} className='h-6 w-6' />
                <div className={`${hideTitle ? "hidden" : "block"}`} >{title}</div>
            </div>
            {notification ?
                <div className={` ${hideTitle ? "absolute right-1 top-1" : ""} h-5 w-5 rounded-full bg-red-600 text-white text-[0.6rem]  flex justify-center items-center`} >{notification}</div>
                : null}
            {subList &&
                <ChevronDownIcon className="h-7 w-7" />
            }
        </a>
    )
}
