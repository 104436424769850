import { api } from './api';

export const getCampaignUsersApi = (params) => {
	return api.post('/campaign-users/approved', params);
};

export const updateCampaignUserApi = (userId, params) => {
	return api.put(`/users/${userId}`, params);
};


export const getCampaignUserByQueryApi = (params) => {
	return api.post('/campaign-users/query/get', params);
};
