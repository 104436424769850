import React from 'react'
import { Dialog, DialogTitle, DialogContent, Avatar } from '@mui/material';
import { BASE_API_URL } from '../../services/config';
import moment from 'moment';
import { CheckCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { formatMoney } from '../../helper';

export default function ViewSingleModel({ open, handleClose, data }) {
    return (
        <Dialog
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='border-b relative'>
                <div onClick={handleClose} className='absolute cursor-pointer right-4 p-3' >
                    <XMarkIcon className='w-7 h-7 text-black' />
                </div>
                <div className="flex gap-3 items-center" >
                    <Avatar
                        alt={data?.user?.firstName}
                        src={`${BASE_API_URL}/${data?.user?.profileImage}`}
                        style={{ height: "50px", width: "50px" }}
                    />
                    <div>
                        <div className="font-poppins" >{data?.user?.firstName + " " + data?.user?.lastName}</div>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent  >
                <h6 className="font-bold mb-4 text-[1.2rem] pt-3" >Member Details</h6>
                <div className='grid lg:grid-cols-2 gap-5' >
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Member ID :</div>
                        <div className="font-poppins" >{data?.user?._id}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Created Date :</div>
                        <div className="font-poppins" >{moment(data?.createdAt).format("DD-MMM-YYYY")}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Mobile No :</div>
                        <div className="font-poppins" >{data?.user?.mobile}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Email :</div>
                        <div className="font-poppins" >{data?.user?.email}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Role :</div>
                        <div className="font-poppins" >{data?.role?.name}</div>
                    </div>
                    <div className="flex items-center justify-start gap-3 w-full" >
                        <div className="font-poppins font-medium" >Campaign User ID :</div>
                        <div className="font-poppins" >{data?._id}</div>
                    </div>
                </div>
                <h6 className="mt-4 mb-4 text-[1.2rem] font-bold" >Check List</h6>
                <table className='w-full border' >
                    <thead className='bg-gray-200' >
                        <tr className="font-poppins font-semibold text-sm" >
                            <td className='py-2 px-2' >#</td>
                            <td className='py-2 px-2' >Item</td>
                            <td className='py-2 px-2' >Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.questions?.map((item, index) => {
                            return (
                                <tr className="font-poppins text-sm even:bg-gray-100" key={index} >
                                    <td className='py-2 px-2' >{index + 1}</td>
                                    <td className='py-2 px-2' >{item?.question_text}</td>
                                    <td className='py-2  px-2' >{item?.isChecked ? <CheckCircleIcon className="text-green-500 w-7 h-7" /> : <XCircleIcon className="text-red-500 w-7 h-7" />}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {data?.donations?.length > 0 ?
                    <div>
                        <h6 className="mt-4 mb-4 text-[1.2rem] font-bold" >Donations</h6>
                        <table className='w-full border' >
                            <thead className='bg-gray-200' >
                                <tr className="font-poppins font-semibold text-sm" >
                                    <td className='py-2 px-2' >#</td>
                                    <td className='py-2 px-2' >ID</td>
                                    <td className='py-2 px-2' >Donated By</td>
                                    <td className='py-2 px-2' >Currency</td>
                                    <td className='py-2 px-2' >Amount</td>
                                    <td className='py-2 px-2' >Amount (LKR)</td>
                                    <td className='py-2 px-2' >Date</td>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.donations?.map((item, index) => {
                                    return (
                                        <tr className="font-poppins text-sm even:bg-gray-100" key={index} >
                                            <td className='py-2 px-2' >{index + 1}</td>
                                            <td className='py-2 px-2 text-xs' >{item?._id}</td>
                                            <td className='py-2 px-2 text-xs' >{item?.firstName} {item?.lastName}</td>
                                            <td className='py-2 px-2 text-xs' >{item?.currency}</td>
                                            <td className='py-2 px-2 text-xs' >{formatMoney(item?.amount)}</td>
                                            <td className='py-2 px-2 text-xs' >{formatMoney(item?.amountLocal)}</td>
                                            <td className='py-2 px-2 text-xs' >{moment(item?.createdAt).format("YYYY-MM-DD")}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    : null}
            </DialogContent>
        </Dialog>
    )
}

