import { api } from './api';

export const getMemberRequestsQuickApi = () => {
    return api.get('/admin/campaign-user-approval-list-quick');
};

export const getMemberRequestsApi = () => {
    return api.get('/admin/campaign-user-approval-list');
};



export const changeStatusMemberApi = (campaignUserId, params) => {
    return api.post(`/admin/campaign-user-approval/${campaignUserId}`, params);
};
