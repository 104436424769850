import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Members from "./pages/Campaigns/Members";
import CampaignTerm from "./pages/Campaigns/CampaignTerm";
import CampaignTermPayments from "./pages/Campaigns/CampaignTermPayments";
import DonationHistory from "./pages/DonationHistory";
import MemberRequests from "./pages/MemberRequests";
import BoardMembers from "./pages/BoardMembers";
import Reports from "./pages/Reports";


import { MainContextProvider } from "./context/MainContext";

export default function App() {
  return (
    <MainContextProvider>
      <BrowserRouter>
        <Routes >
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/campaigns/members" element={<Members />} />
          <Route path="/campaigns/campaign-terms" element={<CampaignTerm />} />
          <Route path="/campaigns/campaign-term-payments" element={<CampaignTermPayments />} />
          <Route path="/donation-history" element={<DonationHistory />} />
          <Route path="/member-requests" element={<MemberRequests />} />
          <Route path="/board-members" element={<BoardMembers />} />
          <Route path="/reports" element={<Reports />} />
        </Routes>
      </BrowserRouter>
    </MainContextProvider>
  )
}
