import React, { useState, useEffect } from 'react'
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout'
import Breadcrumbs from '../../components/Breadcrumbs'
import Title from '../../components/Title'
import CampaignPicker from '../../components/common/CampaignPicker'
import MainPicker from '../../components/common/MainPicker'
import moment from 'moment'
import PrimaryButton from '../../components/PrimaryButton'
import { getCampaignUsersApi } from '../../services/campaignUserApis'
import TableLayout from '../../components/common/TableLayout'
import { EyeIcon } from '@heroicons/react/20/solid'
import ViewModel from '../../components/CampaignTermPayments/ViewModel'
import ConfirmationModel from '../../components/common/ConfirmationModel'
import { formatMoney } from '../../helper'
import { markAsPaidApi } from '../../services/campaignTermPaymentApis'
import { toast } from 'react-toastify'


const breadcrumbData = [
    {
        name: "Campaigns",
        href: null
    },
    {
        name: "Campaign Term Payments",
        href: null
    }
]

export default function CampaignTermPayments() {
    document.title = "CCC Admin - Campaign Term Payments"


    const [campaign, setCampaign] = useState(null)
    const [campaignTerms, setCampaignTerms] = useState([])
    const [campaignTerm, setCampaignTerm] = useState(null)


    const [campaignUsers, setCampaignUser] = useState([])
    const [pageNumber, setPageNumber] = useState(0)

    const [loading, setLoading] = useState(false)

    const [selectedRow, setSelectedRow] = useState(null)
    const [showModel, setShowModel] = useState(false)

    const perPage = 20
    const pagesVisited = pageNumber * perPage
    const pageCount = Math.ceil(campaignUsers?.length / perPage)

    const [showConfirmationModel, setShowConfirmationModel] = useState(false)


    useEffect(() => {
        fetchData()
    }, [])


    const fetchData = async () => {

        let dataForm = {
            campaign: campaign?._id,
        }

        if (dataForm?.campaign === "" || dataForm?.campaign === null) delete dataForm.campaign

        setLoading(true)
        try {
            const { data } = await getCampaignUsersApi(dataForm);
            let filteredData = data?.filter(row => row?.campaignUserTerm)
            if (campaignTerm != null) {
                filteredData = filteredData.filter(row => row?.campaignTerm?._id === campaignTerm)
            }
            setCampaignUser(filteredData)
            setLoading(false)
        } catch (error) {
            setLoading(true)
            console.log(error);
        }
    }

    const onStatusUpdateClick = async () => {
        const userData = localStorage.user ? JSON.parse(localStorage?.user) : null
        try {
            const { data } = await markAsPaidApi({ id: selectedRow?.campaignUserTermPayment?._id, changedBy: userData?._id })
            toast.success("payment marked as paid")
            fetchData()
        } catch (error) {
            console.log(error);
            toast.success(error)
        }
    }
    return (
        <AuthenticatedLayout>
            <div className='p-5' >
                <Breadcrumbs data={breadcrumbData} />
                <Title>Campaign Term Payments</Title>
            </div>
            <div className='grid lg:grid-cols-3 items-end gap-5 flex-wrap w-full px-5 py-10 bg-[#FCFCFF]' >
                <CampaignPicker
                    defaultItemTitle={"All"}
                    onChange={campaign => {
                        if (campaign === null) {
                            setCampaignTerm(null)
                        }
                        setCampaign(campaign)
                        setCampaignTerms(campaign?.campaignTerms)
                    }}
                />
                <MainPicker
                    label={"Campaign Term"}
                    defaultItemTitle={"All"}
                    onChange={value => {
                        if (value === "") {
                            return setCampaignTerm(null)
                        }
                        setCampaignTerm(value)
                    }}
                    value={campaignTerm}
                >
                    {campaignTerms?.map((row, index) => {
                        return (
                            <option key={index} value={row?._id} >{moment(row?.startDate).format("YYYY-MM-DD")} - {moment(row?.endDate).format("YYYY-MM-DD")}</option>
                        )
                    })}
                </MainPicker>
                <PrimaryButton loading={loading}
                    onClick={() => fetchData()}
                >Filter</PrimaryButton>
            </div>
            <TableLayout
                pagination={pageCount}
                changePage={page => setPageNumber(page)}
                loading={loading}
                data={campaignUsers}
                emptyMessage={"No Campaign Term payments found"}


            >
                <thead className="text-xs text-white uppercase bg-app-blue" >
                    <tr>
                        <th scope="col" className="py-3 px-6">#</th>
                        {/* <th scope="col" className="py-3 px-6">Member ID</th> */}
                        <th scope="col" className="py-3 px-6">Name</th>
                        <th scope="col" className="py-3 px-6">Campaign Name | Role</th>
                        <th scope="col" className="py-3 px-6">Email | Mobile</th>
                        <th scope="col" className="py-3 px-6">Campaign Term</th>
                        <th scope="col" className="py-3 px-6">Register Date</th>
                        <th scope="col" className="py-3 px-6">Payment Status</th>
                        <th scope="col" className="py-3 px-6">Amount</th>
                        <th scope="col" className="py-3 px-6">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {campaignUsers?.slice(pagesVisited, pagesVisited + perPage)?.map((row, index) => {
                        return (
                            <tr key={index} className='odd:bg-[#F8FAFB] even:bg-white'  >
                                <td className="py-3 px-6" >{index + 1}</td>
                                {/* <td className="py-3 px-6 text-xs" >{row?._id}</td> */}
                                <td className="py-3 px-6" >{row?.user?.firstName} {row?.user?.lastName}</td>
                                <td className="py-3 px-6" >
                                    <div className='text-center capitalize' >
                                        <b>{row?.campaign?.name}</b>
                                        <br></br> {row?.role?.name}
                                    </div>
                                </td>
                                <td className="py-3 px-6" >
                                    <div className='text-center' >
                                        {row?.user?.email}
                                        <br></br> {row?.user?.mobile}
                                    </div>
                                </td>
                                <td className="py-3 px-6" >{row?.campaignTerm?.startDate && moment(row?.campaignTerm?.startDate).format("YYYY-MM-DD")} - {row?.campaignTerm?.endDate && moment(row?.campaignTerm?.endDate).format("YYYY-MM-DD")}</td>
                                <td className="py-3 px-6" >{moment(row?.user?.createdAt).format("YYYY-MM-DD")}</td>
                                <td>
                                    {row?.campaignUserTermPayment === null &&
                                        <div>No Payment Made</div>
                                    }
                                    {row?.campaignUserTermPayment != null &&
                                        <div className='uppercase' > {row?.campaignUserTermPayment?.status ? row?.campaignUserTermPayment?.status : "Pending"}</div>
                                    }
                                </td>
                                <td >
                                    {row?.campaignUserTermPayment === null &&
                                        <div>-</div>
                                    }
                                    {row?.campaignUserTermPayment != null &&
                                        <>
                                            <div>{row?.campaignUserTermPayment?.currency}</div>
                                            <div>{formatMoney(row?.campaignUserTermPayment?.amount)}</div>
                                        </>
                                    }
                                </td>
                                <td className="py-3 px-2" >
                                    <div className='flex justify-end gap-2' >
                                        {!row?.campaignUserTermPayment?.isPaid &&
                                            <button
                                                onClick={() => {
                                                    setSelectedRow(row)
                                                    setShowConfirmationModel(true)
                                                }}
                                                className='p-2 whitespace-nowrap bg-app-blue rounded-lg text-white' >
                                                Mark As Paid
                                            </button>
                                        }
                                        <button
                                            onClick={() => {
                                                setSelectedRow(row)
                                                setShowModel(true)
                                            }}
                                            className='p-2 bg-black rounded-lg' >
                                            <EyeIcon className='text-white w-4 h-4' />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </TableLayout>

            <ViewModel
                data={selectedRow}
                open={showModel}
                handleClose={() => {
                    setShowModel(false)
                    setTimeout(() => {
                        setSelectedRow(null)
                    }, 560);
                }} />
            <ConfirmationModel
                title={"Are you sure ?"}
                open={showConfirmationModel}
                handleClose={() => setShowConfirmationModel(false)}
                handleCloseAndAction={() => {
                    onStatusUpdateClick()
                    setShowConfirmationModel(false)
                }}
            >
                <p>Are you sure to mark the payment of {selectedRow?.campaignUserTermPayment?.currency} {formatMoney(selectedRow?.campaignUserTermPayment?.amount)} as paid for the user {selectedRow?.user?.firstName} {selectedRow?.user?.lastName}?</p>
                <small>*** Note that you will not be able to change this ***</small>
            </ConfirmationModel>
        </AuthenticatedLayout >
    )
}
