export const createChunks = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array?.length; i += chunkSize) {
        chunks.push(array?.slice(i, i + chunkSize));
    }
    return chunks;
}


export const formatMoney = (
    amount,
    decimalCount = 2,
    decimal = '.',
    thousands = ',',
) => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-' : '';

        let i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimal +
                Math.abs(amount - i)
                    .toFixed(decimalCount)
                    .slice(2)
                : '')
        );
    } catch (e) {
        console.log(e);
    }
};

export const formatCash = (amount) => {
    if (amount < 1e3) return amount;
    if (amount >= 1e3 && amount < 1e6) return +(amount / 1e3).toFixed(1) + "K";
    if (amount >= 1e6 && amount < 1e9) return +(amount / 1e6).toFixed(1) + " million";
    if (amount >= 1e9 && amount < 1e12) return +(amount / 1e9).toFixed(1) + "B";
    if (amount >= 1e12) return +(amount / 1e12).toFixed(1) + "T";
};

export const validateEmail = (email) => {
    const result = String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    if (result == null) {
        return false
    }
    return true
};


export const validateUrl = (url) => {
    const result = String(url)
        .toLowerCase()
        .match(
            /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
        );
    if (result == null) {
        return false
    }
    return true
}


export const getError = (type, errorArray) => {
    let keys = Object.keys(errorArray);
    if (keys.includes(type)) {
        return errorArray[type]
    }
    return null
}


export const isApprovalCheckPass = (votes, total) => {
    let requiredApproval = (2 / 3) * total;
    if (total == 2 && votes >= 1) {
        return true
    }
    if (votes >= requiredApproval) {
        return true;
    }
    return false;
}


export const getApprovalStatus = (votes, total, rejects) => {
    if ((votes + rejects) < total) {
        return "pending"
    }
    if (votes == 0 && rejects == 0) {
        return "pending"
    }
    let requiredApproval = (2 / 3) * total;
    if (total == 2 && votes >= 1) {
        return "approved"
    }
    if (votes >= requiredApproval) {
        return "approved"
    }
    return "rejected"
}