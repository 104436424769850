import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/20/solid';
import PrimaryButton from '../PrimaryButton';
import { getError } from '../../helper';
import { toast } from 'react-toastify';
import { createCommitteeMembersApi, getAllActiveUsersApi } from '../../services/committeeMemberApis';
import MainPicker from '../common/MainPicker'

export default function CreateModel({ open, handleClose, handleCloseAndFetch }) {
    const [errorObject, setErrorObject] = useState({})
    const [loading, setLoading] = useState(false)

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState("")

    useEffect(() => {
        if (open) {
            fetchActiveUsers()
        }

        setSelectedUser('')
        setErrorObject({})
    }, [open])


    const fetchActiveUsers = async () => {
        try {
            const { data } = await getAllActiveUsersApi()
            setUsers(data);
        } catch (error) {
            console.log(error);
        }
    }

    const onCreateClick = async () => {
        if (selectedUser === "") {
            return setErrorObject({ user: "The user field is required" })
        }
        setLoading(true)
        try {
            const { data } = await createCommitteeMembersApi({ user: selectedUser })
            toast.success("Board Member Created")
            setLoading(false)
            handleCloseAndFetch()
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    return (
        <Dialog
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='border-b relative w-[100vw] lg:w-[50vw]'>
                <div onClick={handleClose} className='absolute cursor-pointer right-4 h-[60%] flex justify-center items-center ' >
                    <XMarkIcon className='w-7 h-7 text-black' />
                </div>
                <div>{"Created Board Member"}</div>
            </DialogTitle>
            <DialogContent  >
                <div className='my-10' >
                    <MainPicker
                        error={getError('user', errorObject)}
                        defaultItemTitle={"Select User"}
                        onChange={value => { delete errorObject.user; setSelectedUser(value) }}
                        value={selectedUser}
                    >
                        {users?.map((row, index) => {
                            return (
                                <option key={index} value={row?._id} >{row?.firstName} {row?.lastName} | {row?.email}</option>
                            )
                        })}
                    </MainPicker>
                </div>
                <div className='my-5' >
                    <PrimaryButton loading={loading}
                        onClick={() => onCreateClick()}
                    >{"Create"}</PrimaryButton>
                </div>
            </DialogContent>
        </Dialog>
    )
}
