import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/20/solid';
import PrimaryInput from '../PrimaryInput'
import { getError, validateEmail } from '../../helper';
import moment from 'moment';
import PrimaryButton from '../PrimaryButton';
import { updateCampaignUserApi } from '../../services/campaignUserApis';

import {isPossiblePhoneNumber} from 'react-phone-number-input'




export default function EditModel({ open, handleClose, dataList, handleCloseAndFetch }) {

    const [errorObject, setErrorObject] = useState({})
    const [loading, setLoading] = useState(false)

    const [userEditForm, setUserEditForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
    })

    useEffect(() => {
        setUserEditForm({
            firstName: dataList?.user?.firstName,
            lastName: dataList?.user?.lastName,
            email: dataList?.user?.email,
            mobile: dataList?.user?.mobile,
        })
    }, [dataList])

    useEffect(() => {
     if(!open){
        setErrorObject({})
     }
    }, [open])
    

    const onUpdateClick = async () => {

        let errorList = {}
        if (userEditForm.firstName === "") {
            errorList = { ...errorList, firstName: "The first name field is required" }
        }
        if (userEditForm.lastName === "") {
            errorList = { ...errorList, lastName: "The last name field is required" }
        }

        if (userEditForm.email == "") {
            errorList = { ...errorList, email: "The email field is required" }
        }

        if (userEditForm.email !== "" && !validateEmail(userEditForm.email)) {
            errorList = { ...errorList, email: "The email field is invalid" }
        }
        if (userEditForm.mobile === "") {
            errorList = { ...errorList, mobile: "The mobile field is required" }
        }

        if (userEditForm.mobile !== "" && !isPossiblePhoneNumber(userEditForm.mobile)) {
            errorList = { ...errorList, mobile: "The mobile field is invalid" }
        }
        if (Object.keys(errorList)?.length > 0) {
            setErrorObject(errorList)
            return false
        }

        setLoading(true)
        try {
            const { data } = await updateCampaignUserApi(dataList?.user?._id, userEditForm)
            setLoading(false)
            handleCloseAndFetch()
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }


    return (
        <Dialog
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='border-b relative w-[100vw] lg:w-[50vw]'>
                <div onClick={handleClose} className='absolute cursor-pointer right-4 h-[60%] flex justify-center items-center ' >
                    <XMarkIcon className='w-7 h-7 text-black' />
                </div>
                <div>Edit User</div>
            </DialogTitle>
            <DialogContent  >
                <div className='grid lg:grid-cols-2 gap-5 py-5' >
                    <PrimaryInput
                        label={"Member ID"}
                        value={dataList?._id}
                        disabled
                    />
                    <PrimaryInput
                        label={"Joined Date"}
                        value={moment(dataList?.createdAt).format('Do MMMM YYYY')}
                        disabled
                    />
                    <PrimaryInput
                        error={getError('firstName', errorObject)}
                        label={"First Name *"}
                        placeholder="First Name"
                        value={userEditForm?.firstName}
                        onChange={text => { delete errorObject.firstName; setUserEditForm({ ...userEditForm, firstName: text }) }}
                    />
                    <PrimaryInput
                        error={getError('lastName', errorObject)}
                        label={"Last Name *"}
                        placeholder="Last Name"
                        value={userEditForm?.lastName}
                        onChange={text => { delete errorObject.lastName; setUserEditForm({ ...userEditForm, lastName: text }) }}
                    />
                    <PrimaryInput
                        error={getError('mobile', errorObject)}
                        label={"Mobile *"}
                        placeholder="Mobile"
                        value={userEditForm?.mobile}
                        onChange={text => { delete errorObject.mobile; setUserEditForm({ ...userEditForm, mobile: text }) }}
                    />
                    <PrimaryInput
                        error={getError('email', errorObject)}
                        label={"Email *"}
                        placeholder="Email"
                        value={userEditForm?.email}
                        onChange={text => { delete errorObject.email; setUserEditForm({ ...userEditForm, email: text }) }}
                    />
                </div>
                <div className='my-5' >
                    <PrimaryButton loading={loading} onClick={() => onUpdateClick()} >Update Member</PrimaryButton>
                </div>
            </DialogContent>
        </Dialog>
    )
}
