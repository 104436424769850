import React, { useContext, useEffect, useState } from 'react'

import AuthenticatedLayout from '../layouts/AuthenticatedLayout'
import Breadcrumbs from '../components/Breadcrumbs'
import Title from '../components/Title'
import Tab from '../components/MemberRequest/Tab'
import { MainContext } from '../context/MainContext'

const breadcrumbData = [
    {
        name: "Member Requests",
        href: null
    },
]

export default function MemberRequests() {
    const { fetchMemberRequestData, fetchMemberRequests } = useContext(MainContext)
    document.title = "CCC Admin - Member Requests"

    const [selectedTab, setSelectedTab] = useState(0)
    useEffect(() => {
        fetchMemberRequestData()
    }, [])


    return (
        <AuthenticatedLayout>
            <div className='p-5' >
                <Breadcrumbs data={breadcrumbData} />
                <Title>Member Requests</Title>
            </div>
            <button onClick={() => {
                fetchMemberRequestData();
                fetchMemberRequests();
            }} className='float-right my-5 py-2 px-20 bg-green-800 text-white rounded' >Reload</button>
            <div className='w-full bg-[#F4F0F0] flex flex-col lg:flex-row justify-center items-center py-5 gap-10 px-10' >
                <button className={`${selectedTab === 0 ? "bg-app-blue text-white" : "bg-transparent text-black"} px-10 py-3 `} onClick={() => {
                    setSelectedTab(0)
                }} >Pending Request</button>
                <button className={`${selectedTab === 1 ? "bg-app-blue text-white" : "bg-transparent text-black"} px-10 py-3`} onClick={() => {
                    setSelectedTab(1)
                }} >Board Member Approvals</button>
            </div>
            <div className='p-5' >
                <Tab selected={selectedTab === 0} selectedTab={selectedTab} />
                <Tab selected={selectedTab === 1} selectedTab={selectedTab} />
            </div>
        </AuthenticatedLayout>
    )
}
