import { api } from './api';

export const createCampaignTermApi = (params) => {
    return api.post('/campaign-terms', params);
};


export const updateCampaignTermApi = (campaignTermId, params) => {
    return api.put(`/campaign-terms/${campaignTermId}`, params);
};

