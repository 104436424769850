import React from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

export default function MainPicker({ value, onChange, children, label, defaultItemTitle, error }) {
    return (
        <div className='flex flex-col gap-1 w-full' >
            <label>{label}</label>
            <div className='relative w-full' >
                <select
                    onChange={e => onChange(e.target.value)}
                    value={value}
                    className={`${error ? "border-red-600" : "border-gray-200"} border w-full rounded-lg p-2 appearance-none`} >
                    <option value={""} >{defaultItemTitle || "Select Item"}</option>
                    {children}
                </select>
                <ChevronDownIcon className='text-black w-5 h-5 absolute right-2 top-[30%]' />
            </div>
            {error && <small className='text-red-600' >{error}</small>}
        </div>
    )
}
