import React, { useState, useEffect } from 'react'
import AuthenticatedLayout from '../layouts/AuthenticatedLayout'
import Breadcrumbs from '../components/Breadcrumbs'
import Title from '../components/Title'
import Paper from '@mui/material/Paper';
import { getCampaignsDashboardApi } from '../services/campaignApis';
import { BASE_API_URL } from '../services/config';
import { ChevronDownIcon } from '@heroicons/react/24/solid'

import { formatMoney } from '../helper'


import { Accordion, AccordionSummary, AccordionDetails,Divider } from '@mui/material';
import moment from 'moment';
import LoadingSpinner from '../components/common/LoadingSpinner';

export default function Dashboard() {
    document.title = "CCC Admin - Dashboard"

    const [campaigns, setCampaigns] = useState([])
    const [mainExpand, setMainExpand] = useState(true)
    const [secondaryExpand, setSecondaryExpand] = useState(true)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        fetchCampaignData()
    }, [])

    const fetchCampaignData = async () => {
        setLoading(true)
        try {
            const { data } = await getCampaignsDashboardApi()
            setCampaigns(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    return (
        <AuthenticatedLayout>
            <div className='p-5' >
                <Breadcrumbs />
                <Title>Dashboard</Title>
            </div>
            {loading &&
                <LoadingSpinner />
            }
            {!loading &&
                <>
                    <div className='p-5 grid sm:grid-cols-2 lg:grid-cols-3 items-start gap-10' >
                        {campaigns?.sort((a, b) => a?.sortOrder - b?.sortOrder)?.map((row, index) => {
                            return (
                                <Paper key={index} elevation={3} className='p-3 text-sm' >
                                    <div className='flex items-start gap-5 capitalize' >
                                        <div>
                                            <img alt={row?.name} src={`${BASE_API_URL}/${row?.mainImage}`} className='w-32 border rounded' />
                                        </div>
                                        <div>
                                            <div className='font-semibold' >{row?.name}</div>
                                            <div className='flex items-center gap-2 text-sm mt-2' >
                                                <div>Total Member :</div>
                                                <div>{row?.campaignUsers?.length}</div>
                                            </div>
                                            {row?.campaignRoles?.map((campaignRole, campaignRoleIndex) => {
                                                const filteredCampaignUsers = row?.campaignUsers?.filter(row => row?.role === campaignRole?._id)
                                                return (
                                                    <div key={campaignRoleIndex} className='flex items-center gap-2 text-sm mt-1' >
                                                        <div>{campaignRole?.name} :</div>
                                                        <div>{filteredCampaignUsers?.length}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Paper>
                            )
                        })}
                    </div>
                    <Divider />
                    <div className='p-5 grid sm:grid-cols-2 lg:grid-cols-3 items-start gap-10' >
                        {campaigns?.sort((a, b) => a?.sortOrder - b?.sortOrder)?.map((row, index) => {
                            return (
                                <Accordion className='text-sm' expanded={mainExpand} onChange={() => setMainExpand(!mainExpand)} key={index} >
                                    <AccordionSummary
                                        expandIcon={<ChevronDownIcon className='text-[#00000047] h-6 w-6' />}
                                        aria-controls={`panel${index}a-header`}
                                        id={`panel${index}a-header`}
                                        sx={{ backgroundColor: "#F9F9F9" }}
                                    >
                                        <div className='flex items-center gap-5' >
                                            <img alt={row?.name} src={`${BASE_API_URL}/${row?.mainImage}`} className='w-20 border rounded' />
                                            <div className='font-semibold capitalize' >{row?.name}</div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {row?.campaignTerms?.length === 0 &&
                                            <div>No Campaigns</div>
                                        }
                                        {row?.campaignTerms?.map((campaignTerm, campaignTermIndex) => {
                                            const collectedAmount = campaignTerm?.donations?.reduce((acc, curr) => {
                                                return acc + parseFloat(curr?.amountLocal)
                                            }, 0)
                                            return (
                                                <Accordion expanded={secondaryExpand} onChange={() => setSecondaryExpand(!secondaryExpand)} key={campaignTermIndex} >
                                                    <AccordionSummary
                                                        expandIcon={<ChevronDownIcon className='text-[#00000047] h-6 w-6' />}
                                                        aria-controls={`panel${campaignTermIndex}a-header`}
                                                        id={`panel${campaignTermIndex}a-header`}
                                                        sx={{ backgroundColor: "#F9F9F9" }}
                                                    >
                                                        <div className='flex items-center gap-5 text-sm' >
                                                            <div className='capitalize' >{campaignTerm?.startDate ? moment(campaignTerm?.startDate).format("Do MMM YYYY") : ""} - {campaignTerm?.endDate ? moment(campaignTerm?.endDate).format("Do MMM YYYY") : ""}</div>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className='flex items-center gap-2 text-sm mt-2' >
                                                            <div>Target :</div>
                                                            <div className='font-semibold' >LKR {formatMoney(campaignTerm?.target)}</div>
                                                        </div>
                                                        <div className='flex items-center gap-2 text-sm mt-2' >
                                                            <div>Collected Amount :</div>
                                                            <div className='font-semibold' >LKR {formatMoney(collectedAmount)}</div>
                                                        </div>
                                                        <div className='flex items-center gap-2 text-sm mt-2' >
                                                            <div>Participate Number of users :</div>
                                                            <div className='font-semibold' >{campaignTerm?.campaignUsers?.length}</div>
                                                        </div>

                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}

                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>
                </>
            }
        </AuthenticatedLayout>
    )
}
