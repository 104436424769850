import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../../context/MainContext'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

export default function CampaignPicker({ onChange, defaultItemTitle, error, itemRequired }) {
    const { fetchCampaigns, campaigns,loadingBackground } = useContext(MainContext)
    const [value, setValue] = useState("")

    useEffect(() => {
        fetchCampaigns()
    }, [])

    return (
        <div className='flex flex-col gap-1 w-full' >
            <label>{itemRequired ? "Campaign *":"Campaign"}</label>
            <div className='relative w-full' >
                <select
                    disabled={loadingBackground}
                    id='campaignPicker'
                    onChange={e => {
                        const campaignId = e.target.value
                        setValue(campaignId)
                        if (campaignId === "") {
                            return onChange(null);
                        }
                        const campaign = campaigns?.find(row => row?._id === campaignId)
                        onChange(campaign);
                    }}
                    value={value}
                    className={`${error ? "border-red-600" : "border-gray-200"} disabled:bg-gray-400 border w-full rounded-lg p-2 appearance-none`} >
                    <option value={""} >{defaultItemTitle || "Select Campaign"}</option>
                    {campaigns?.map((row, index) => {
                        return (
                            <option key={index} value={row?.id} >{row?.name}</option>
                        )
                    })}
                </select>
                <ChevronDownIcon className='text-black w-5 h-5 absolute right-2 top-[30%]' />
            </div>
            {error && <small className='text-red-600' >{error}</small>}
        </div>
    )
}
