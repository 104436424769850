import React from 'react'

export default function PrimaryButton(props) {
    return (
        <button
            disabled={props.loading}
            {...props}
            className={`  ${props.className} disabled:bg-gray-400 px-20 text-white bg-app-blue hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 h-10 text-center`}>{props.loading ? "Loading" : props.children}</button>
    )
}
