import React, { useState, useEffect } from 'react'
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout'
import Breadcrumbs from '../../components/Breadcrumbs'
import Title from '../../components/Title'
import CampaignPicker from '../../components/common/CampaignPicker'
import MainPicker from '../../components/common/MainPicker'
import PrimaryButton from '../../components/PrimaryButton'
import { getCampaignUsersApi } from '../../services/campaignUserApis'
import TableLayout from '../../components/common/TableLayout'
import moment from 'moment'
import { EyeIcon, PencilIcon } from '@heroicons/react/20/solid'
import ViewSingleModel from '../../components/Members/ViewSingleModel'
import EditModel from '../../components/Members/EditModel'


const breadcrumbData = [
    {
        name: "Campaigns",
        href: null
    },
    {
        name: "Members",
        href: null
    }
]

export default function Members() {

    document.title = "CCC Admin - Members"

    const [campaign, setCampaign] = useState(null)
    const [campaignRoles, setCampaignRoles] = useState([])
    const [campaignRole, setCampaignRole] = useState(null)


    const [selectedMember, setSelectedMember] = useState(null)
    const [showModel, setShowModel] = useState(false)
    const [showEditModel, setShowEditModel] = useState(false)


    const [members, setMembers] = useState([])
    const [pageNumber, setPageNumber] = useState(0)


    const [loading, setLoading] = useState(false)




    useEffect(() => {
        fetchMembers()
    }, [])


    const fetchMembers = async () => {
        setLoading(true)

        let queryData = { campaign: campaign?._id, role: campaignRole }
        if (queryData?.campaign === null) delete queryData.campaign
        if (queryData?.role === null) delete queryData.role

        try {
            const { data } = await getCampaignUsersApi(queryData);
            let memberData = [...data];
            setMembers(memberData)
            setLoading(false)
        } catch (error) {
            setLoading(true)
            console.log(error);
        }
    }

    const perPage = 20
    const pagesVisited = pageNumber * perPage
    const pageCount = Math.ceil(members?.length / perPage)

    return (
        <AuthenticatedLayout>
            <div className='p-5' >
                <Breadcrumbs data={breadcrumbData} />
                <Title>Members</Title>
            </div>
            <div className='grid lg:grid-cols-3 items-end gap-5 flex-wrap w-full px-5 py-10 bg-[#FCFCFF]' >
                <CampaignPicker
                    defaultItemTitle={"All"}
                    onChange={campaign => {
                        setCampaign(campaign)
                        setCampaignRoles(campaign?.campaignRoles)
                    }}
                />
                <MainPicker
                    label={"Role"}
                    defaultItemTitle={"All"}
                    onChange={value => {
                        if (value === "") {
                            return setCampaignRole(null)
                        }
                        setCampaignRole(value)
                    }}
                    value={campaignRole}
                >
                    {campaignRoles?.map((row, index) => {
                        return (
                            <option key={index} value={row?._id} >{row?.name}</option>
                        )
                    })}
                </MainPicker>
                <PrimaryButton loading={loading} onClick={() => fetchMembers()} >Filter</PrimaryButton>
            </div>

            <TableLayout
                pagination={pageCount}
                changePage={page => setPageNumber(page)}
                loading={loading}
                data={members}
                emptyMessage={"No Members Found"}


            >
                <thead className="text-xs text-white uppercase bg-app-blue" >
                    <tr>
                        <th scope="col" className="py-3 px-6">#</th>
                        <th scope="col" className="py-3 px-6">Member ID</th>
                        <th scope="col" className="py-3 px-6">First Name</th>
                        <th scope="col" className="py-3 px-6">Last Name</th>
                        <th scope="col" className="py-3 px-6">Campaign Name</th>
                        <th scope="col" className="py-3 px-6">Role</th>
                        <th scope="col" className="py-3 px-6">Contact No</th>
                        <th scope="col" className="py-3 px-6">Join Date</th>
                        <th scope="col" className="py-3 px-6"></th>
                    </tr>
                </thead>
                <tbody>
                    {members?.slice(pagesVisited, pagesVisited + perPage)?.map((row, index) => {
                        return (
                            <tr key={index} className='odd:bg-[#F8FAFB] even:bg-white'  >
                                <td className="py-3 px-6" >{index + 1}</td>
                                <td className="py-3 px-6" >{row?._id}</td>
                                <td className="py-3 px-6" >{row?.user?.firstName}</td>
                                <td className="py-3 px-6" >{row?.user?.lastName}</td>
                                <td className="py-3 px-6" >{row?.campaign?.name}</td>
                                <td className="py-3 px-6" >{row?.role?.name}</td>
                                <td className="py-3 px-6" >{row?.user?.mobile}</td>
                                <td className="py-3 px-6" >{moment(row?.user?.createdAt).format("YYYY-MM-DD")}</td>
                                <td className="py-3 px-2" >
                                    <div className='flex gap-2' >
                                        <button
                                            onClick={() => {
                                                setSelectedMember(row)
                                                setShowModel(true)
                                            }}
                                            className='p-2 bg-black rounded-lg' >
                                            <EyeIcon className='text-white w-4 h-4' />
                                        </button>
                                        <button
                                            onClick={() => {
                                                setSelectedMember(row)
                                                setShowEditModel(true)
                                            }}
                                            className='p-2 bg-black rounded-lg' >
                                            <PencilIcon className='text-white w-4 h-4' />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </TableLayout>
            <ViewSingleModel
                data={selectedMember}
                open={showModel}
                handleClose={() => {
                    setShowModel(false)
                    setTimeout(() => {
                        setSelectedMember(null)
                    }, 560);
                }} />

            <EditModel
                dataList={selectedMember}
                open={showEditModel}
                handleCloseAndFetch={() => {
                    setShowEditModel(false)
                    setTimeout(() => {
                        setSelectedMember(null)
                    }, 560);
                    fetchMembers()
                }}
                handleClose={() => {
                    setShowEditModel(false)
                    setTimeout(() => {
                        setSelectedMember(null)
                    }, 560);
                }} />
        </AuthenticatedLayout>
    )
}
