import React from 'react'
import Pagination from '@mui/material/Pagination';
import LoadingSpinner from './LoadingSpinner';

export default function TableLayout({ data, loading, emptyMessage, children, pagination, className, changePage }) {

    if (loading) {
        return <LoadingSpinner />
    }
    if (data?.length === 0) {
        return (
            <div className='w-full h-[22rem] flex flex-col justify-center items-center text-black font-semibold' >
                <img alt='data-not-found' src='/images/data-not-found.png' className='w-[15rem]' />
                <div>{emptyMessage}</div>
            </div>
        )
    }

    return (
        <div className="overflow-x-auto relative mt-10 w-full">
            <table className={`${className} w-full text-sm text-left text-gray-500 border`}>
                {children}
            </table>

            {pagination ?
                <div className='flex justify-end py-5 px-5' >
                    <Pagination onChange={(e, value) => changePage(value - 1)} count={pagination} variant="outlined" shape="rounded" />
                </div>
                : null}
        </div>
    )
}
