import React, { useState } from 'react'
import AuthenticatedLayout from '../layouts/AuthenticatedLayout'
import Breadcrumbs from '../components/Breadcrumbs'
import Title from '../components/Title'
import CampaignPicker from '../components/common/CampaignPicker'
import PrimaryButton from '../components/PrimaryButton'
import MainPicker from '../components/common/MainPicker'
import moment from 'moment'
import Checkbox from '../components/common/Checkbox'
import PrimaryInput from '../components/PrimaryInput'
import { BASE_API_URL } from '../services/config'
import { toast } from 'react-toastify'

const breadcrumbData = [
    {
        name: "Reports",
        href: null
    },
]

export default function Reports() {
    document.title = "CCC Admin - Reports"

    const [byTerm, setByTerm] = useState(false)

    const [campaignTerms, setCampaignTerms] = useState([])
    const [campaignTerm, setCampaignTerm] = useState("")
    const [reportType, setReportType] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")




    const onDownloadClick = async () => {

        if (byTerm && campaignTerm === "") {
            return toast.error("The campaign term is required")
        }

        if (!byTerm && startDate === "" && endDate === "") {
            toast.error("The start date is required")
            return toast.error("The end date is required")
        }

        if (!byTerm && moment(startDate).isAfter(endDate)) {
            return toast.error("The start date cannot be a date after end date")
        }

        if (reportType === "") {
            return toast.error("The report type field")
        }

        if (reportType === "donation") {
            return window.open(BASE_API_URL + `/reports/donation-report?fromDate=${startDate}&toDate=${endDate}&termId=${campaignTerm === null ? "" : campaignTerm}`);
        }
        window.open(BASE_API_URL + `/reports/contribute-report?fromDate=${startDate}&toDate=${endDate}&termId=${campaignTerm === null ? "" : campaignTerm}`);
    }



    return (
        <AuthenticatedLayout>
            <div className='p-5' >
                <Breadcrumbs data={breadcrumbData} />
                <Title>Reports</Title>
            </div>
            <div className='grid lg:grid-cols-2 items-end gap-5 flex-wrap w-full px-5 py-10 bg-[#FCFCFF]' >
                {byTerm &&
                    <>
                        <CampaignPicker
                            itemRequired
                            defaultItemTitle={"-"}
                            onChange={campaign => {
                                if (campaign === null) {
                                    setCampaignTerm("")
                                }
                                setCampaignTerms(campaign?.campaignTerms)
                            }}
                        />
                        <MainPicker
                            label={"Campaign Term"}
                            defaultItemTitle={"-"}
                            onChange={value => setCampaignTerm(value)}
                            value={campaignTerm}
                        >
                            {campaignTerms?.map((row, index) => {
                                return (
                                    <option key={index} value={row?._id} >{moment(row?.startDate).format("YYYY-MM-DD")} - {moment(row?.endDate).format("YYYY-MM-DD")}</option>
                                )
                            })}
                        </MainPicker>
                    </>
                }
                {!byTerm &&
                    <>
                        <PrimaryInput
                            label={"Start Date *"}
                            type="date"
                            value={startDate}
                            onChange={text => setStartDate(text)}
                        />
                        <PrimaryInput
                            label={"End Date *"}
                            type="date"
                            value={endDate}
                            onChange={text => setEndDate(text)}
                        />
                    </>
                }
                <MainPicker
                    label={"Donation Type *"}
                    defaultItemTitle={"-"}
                    onChange={value => setReportType(value)}
                    value={reportType}
                >
                    <option value={'donation'} >Donation Report</option>
                    <option value={'contributor'} >Contributor Report</option>
                </MainPicker>
                <PrimaryButton
                    onClick={() => onDownloadClick()}
                >Download Report</PrimaryButton>
            </div>
            <div className='px-5' >
                <Checkbox id={"byTerm"} checked={byTerm} onChange={() => {
                    setByTerm(!byTerm)
                    if (!byTerm === false) {
                        setCampaignTerms([])
                        setCampaignTerm(null)
                        return
                    }
                    setStartDate("")
                    setEndDate("")
                }} >By Campaign Terms</Checkbox>
            </div>

        </AuthenticatedLayout>
    )
}
